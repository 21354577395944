import { IInObjectDto } from 'helpers/utils';
import { EnumLegalEntity, EnumListName, EnumWeekDay } from 'constants/enum';
import { EnumResponseStatus } from 'constants/errors';

export enum EnumResponseListType {
  mLearnTaskList = 'mLearnTaskList', // NO DESCRIPTION
  predictorModelList = 'predictorModelList', // NO DESCRIPTION
  rejectList = 'rejectList', // NO DESCRIPTION
  reklList = 'reklList', // NO DESCRIPTION
  reklBundleList = 'reklBundleList', // NO DESCRIPTION
  userList = 'userList', // NO DESCRIPTION
  sessionList = 'sessionList', // NO DESCRIPTION
  organizationList = 'organizationList', // NO DESCRIPTION
  organizationPaymentList = 'organizationPaymentList', // NO DESCRIPTION
  campaignList = 'campaignList', // NO DESCRIPTION
  productList = 'productList', // NO DESCRIPTION
  segmentList = 'segmentList', // NO DESCRIPTION
  iabCategoryList = 'iabCategoryList', // NO DESCRIPTION
  countryList = 'countryList', // NO DESCRIPTION
  languageList = 'languageList', // NO DESCRIPTION
  creativeList = 'creativeList', // NO DESCRIPTION
  exchangeList = 'exchangeList', // NO DESCRIPTION
  sspList = 'sspList', // NO DESCRIPTION
  exchangeLossReasonList = 'exchangeLossReasonList', // NO DESCRIPTION
  historyLogList = 'historyLogList', // NO DESCRIPTION
  connectionTypeList = 'connectionTypeList', // NO DESCRIPTION
  resourceList = 'resourceList', // NO DESCRIPTION
  trackerList = 'trackerList', // NO DESCRIPTION
  storeCategoryList = 'storeCategoryList', // NO DESCRIPTION
  markupList = 'markupList', // NO DESCRIPTION
  bundleList = 'bundleList', // NO DESCRIPTION
  reportTaskPrototypeList = 'reportTaskPrototypeList', // NO DESCRIPTION
  reportTaskList = 'reportTaskList', // NO DESCRIPTION
  timeZoneList = 'timeZoneList', // NO DESCRIPTION
  genreList = 'genreList', // NO DESCRIPTION
}

export enum EnumLeftOption {
  impression = 'impression', // Left operand type
  click = 'click', // Left operand type
  install = 'install', // Left operand type
  dspSpend = 'dspSpend', // Left operand type,
}

export enum EnumRightOption {
  impression = 'impression', // Right operand
  click = 'click', // Right operand
  install = 'install', // Right operand
  dspSpend = 'dspSpend', // Right operand,
}

export enum EnumAction {
  pauseAndAlert = 'pauseAndAlert', // Action on right type
  pause = 'pause', // Action on right type,
}

export enum EnumAttributionType {
  byEvent = 'byEvent', // Attribution type (by bid or event timestamp)
  byImpression = 'byImpression', // Attribution type (by bid or event timestamp)
  byConv = 'byConv', // Attribution type (by bid or event timestamp),
}

export enum EnumResourceVariableField {
  resourceID = 'resourceID',
  filePath = 'filePath',
  width = 'width',
  height = 'height',
  duration = 'duration',
  mimeType = 'mimeType',
  fileHash = 'fileHash',
  fileSize = 'fileSize',
}

export enum EnumOrganizationStatsRevenueGroup {
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
}

export enum EnumStatsColumnName {
  operationSystem = 'operationSystem', // NO DESCRIPTION
  operationSystemVersion = 'operationSystemVersion', // NO DESCRIPTION
  deviceType = 'deviceType', // NO DESCRIPTION
  make = 'make', // NO DESCRIPTION
  model = 'model', // NO DESCRIPTION
  application = 'application', // NO DESCRIPTION
  creativeID = 'creativeID', // NO DESCRIPTION
  country = 'country', // NO DESCRIPTION
  city = 'city', // NO DESCRIPTION
  hour = 'hour', // NO DESCRIPTION
  exchange = 'exchange', // NO DESCRIPTION
  day = 'day', // NO DESCRIPTION
  exchangeCategory = 'exchangeCategory', // NO DESCRIPTION
  campaignID = 'campaignID', // NO DESCRIPTION
  region = 'region', // NO DESCRIPTION
  placementID = 'placementID', // NO DESCRIPTION
  carrier = 'carrier', // NO DESCRIPTION
  hwVer = 'hwVer', // NO DESCRIPTION
  rawPrice = 'rawPrice', // NO DESCRIPTION
  creativeHash = 'creativeHash', // NO DESCRIPTION
  productID = 'productID', // NO DESCRIPTION
  currency = 'currency', // NO DESCRIPTION
  organizationID = 'organizationID', // NO DESCRIPTION
  campaignName = 'campaignName', // NO DESCRIPTION
  advertiserID = 'advertiserID', // NO DESCRIPTION
  connectionType = 'connectionType', // NO DESCRIPTION
  rewarded = 'rewarded', // NO DESCRIPTION
  weekDay = 'weekDay', // NO DESCRIPTION
  impWidth = 'impWidth', // NO DESCRIPTION
  impHeight = 'impHeight', // NO DESCRIPTION
  advertiserProductName = 'advertiserProductName', // NO DESCRIPTION
  dealID = 'dealID', // NO DESCRIPTION
  hasPMP = 'hasPMP', // NO DESCRIPTION
  adBundle = 'adBundle', // NO DESCRIPTION
  interstitial = 'interstitial', // NO DESCRIPTION
  impressionDay = 'impressionDay', // NO DESCRIPTION
  impressionHour = 'impressionHour', // NO DESCRIPTION
  hasIFA = 'hasIFA', // NO DESCRIPTION
  roundedBidFloor = 'roundedBidFloor', // NO DESCRIPTION
  convDay = 'convDay', // NO DESCRIPTION
  convHour = 'convHour', // NO DESCRIPTION
  predictorProbability = 'predictorProbability', // NO DESCRIPTION
  impPos1h = 'impPos1h', // NO DESCRIPTION
  impPos3h = 'impPos3h', // NO DESCRIPTION
  impPos12h = 'impPos12h', // NO DESCRIPTION
  impPos1d = 'impPos1d', // NO DESCRIPTION
  impPos7d = 'impPos7d', // NO DESCRIPTION
  lat = 'lat', // NO DESCRIPTION
  lon = 'lon', // NO DESCRIPTION
  gender = 'gender', // NO DESCRIPTION
  yob = 'yob', // NO DESCRIPTION
  timeSeriesAt = 'timeSeriesAt', // NO DESCRIPTION
  target = 'target', // NO DESCRIPTION
  adType = 'adType', // NO DESCRIPTION
  organization = 'organization', // NO DESCRIPTION
  losses = 'losses', // NO DESCRIPTION
  impToLosses = 'impToLosses', // NO DESCRIPTION
  deviceAgeDays = 'deviceAgeDays', // NO DESCRIPTION
  deviceAgeHours = 'deviceAgeHours', // NO DESCRIPTION
  deviceAgeDecay = 'deviceAgeDecay', // NO DESCRIPTION
  applicationName = 'applicationName', // NO DESCRIPTION
  conversions = 'conversions', // NO DESCRIPTION
  dspSpend = 'dspSpend', // NO DESCRIPTION
  bidCPM = 'bidCPM', // NO DESCRIPTION
  impressions = 'impressions', // NO DESCRIPTION
  clicks = 'clicks', // NO DESCRIPTION
  ctr = 'ctr', // NO DESCRIPTION
  ecpm = 'ecpm', // NO DESCRIPTION
  cpi = 'cpi', // NO DESCRIPTION
  ecpc = 'ecpc', // NO DESCRIPTION
  cr = 'cr', // NO DESCRIPTION
  advSpend = 'advSpend', // NO DESCRIPTION
  dspProfit = 'dspProfit', // NO DESCRIPTION
  dspRoi = 'dspRoi', // NO DESCRIPTION
  hourPace = 'hourPace', // NO DESCRIPTION
  i2C = 'i2C', // NO DESCRIPTION
  postClicks = 'postClicks', // NO DESCRIPTION
  postViews = 'postViews', // NO DESCRIPTION
  goal1 = 'goal1', // NO DESCRIPTION
  goal2 = 'goal2', // NO DESCRIPTION
  goal3 = 'goal3', // NO DESCRIPTION
  goal4 = 'goal4', // NO DESCRIPTION
  goal5 = 'goal5', // NO DESCRIPTION
  goal6 = 'goal6', // NO DESCRIPTION
  purchases = 'purchases', // NO DESCRIPTION
  purchasesCount = 'purchasesCount', // NO DESCRIPTION
  winRate = 'winRate', // NO DESCRIPTION
  bids = 'bids', // NO DESCRIPTION
  uniqImpressions = 'uniqImpressions', // NO DESCRIPTION
  uniqImpRate = 'uniqImpRate', // NO DESCRIPTION
  uniqClicks = 'uniqClicks', // NO DESCRIPTION
  uniqClickRate = 'uniqClickRate', // NO DESCRIPTION
  rejects = 'rejects', // NO DESCRIPTION
  roas = 'roas', // NO DESCRIPTION
  retentionRate = 'retentionRate', // NO DESCRIPTION
  events = 'events', // NO DESCRIPTION
  bidFloor = 'bidFloor', // NO DESCRIPTION
  ecpa1 = 'ecpa1', // NO DESCRIPTION
  ecpa2 = 'ecpa2', // NO DESCRIPTION
  ecpa3 = 'ecpa3', // NO DESCRIPTION
  ecpa4 = 'ecpa4', // NO DESCRIPTION
  ecpa5 = 'ecpa5', // NO DESCRIPTION
  ecpa6 = 'ecpa6', // NO DESCRIPTION
  importantGoals = 'importantGoals', // NO DESCRIPTION
  retentionRateEvents = 'retentionRateEvents', // NO DESCRIPTION
  retentionRateInstalls = 'retentionRateInstalls', // NO DESCRIPTION
  roasPurchases = 'roasPurchases', // NO DESCRIPTION
  i2G = 'i2G', // NO DESCRIPTION
  bti = 'bti', // NO DESCRIPTION
  allClicks = 'allClicks', // NO DESCRIPTION
  skanInstalls = 'skanInstalls', // NO DESCRIPTION
  createdAt = 'createdAt', // NO DESCRIPTION
  budget = 'budget', // NO DESCRIPTION
  totalDspSpend = 'totalDspSpend', // NO DESCRIPTION
  appName = 'appName', // NO DESCRIPTION
  storeID = 'storeID', // NO DESCRIPTION
  creativeUrl = 'creativeUrl', // NO DESCRIPTION
  creativeName = 'creativeName', // NO DESCRIPTION
  adRoas = 'adRoas', // NO DESCRIPTION
  impWinRate = 'impWinRate',
  ipSubnetwork = 'ipSubnetwork',
  ipWideNetwork = 'ipWideNetwork',
  hourActivityRatio = 'hourActivityRatio',
  btit = 'btit',
  overPrice = 'overPrice',
  targetStatsMap = 'targetStatsMap',
  skadNetworkMap = 'skadNetworkMap'
}

export enum EnumOrderingDirection {
  asc = 'asc', // Направление сортировки
  desc = 'desc', // Направление сортировки,
}

export enum EnumColumnDataTableTypeList {
  advertiserList = 'advertiserList',
  campaignList = 'campaignList',
  campaignDetails = 'campaignDetails',
}
export enum EnumVisibility {
  hidden = 'hidden', // Visibility type
  visible = 'visible', // Visibility type,
  prohibited = 'prohibited', // Visibility type,
}

export enum EnumState {
  grant = 'grant',
  revoke = 'revoke',
}

export enum EnumMarkupState {
  Enabled = 'enabled',
  Testing = 'testing',
  Archived = 'archived',
}

export interface OrganizationPaymentRequestRequestDto {
  /** Client request ID */
  requestID: string;
  /** Client version */
  clientVersion: string;
  /** Custom result list */
  responseResultList: EnumResponseListType[];
  /** Token */
  sessionToken: string;
  /** Admin session token */
  adminSessionToken: string;
  /** Use rekl figures layer */
  useReklFigures: boolean;
  /** Are request dates in UTC already */
  requestTzUtc: boolean;
  /**
   * Organization ID
   * @format int32
   */
  organizationID: number;
  /**
   * AmountGetOrganizationBalanceMap
   * @format double
   */
  amount: number;
  /** Message (additional payment data) */
  message: string;
}

export enum EnumTrackerLinkType {
  Click = 'click',
  Impression = 'impression',
}

export enum EnumTrackerCallType {
  Redirect = 'redirect',
  S2S = 's2S',
}

export interface SspDto {
  sspID: string; // ID
  title: string; // Name
}

export enum EnumOrientation {
  none = 'none', // Creative orientation
  landscape = 'landscape', // Creative orientation
  portrait = 'portrait', // Creative orientation,
}
export enum EnumImgType {
  icon = 'icon', // OpenRTB Image Asset Type (OpenRTB Native Spec 7.7)
  logo = 'logo', // OpenRTB Image Asset Type (OpenRTB Native Spec 7.7)
  main = 'main', // OpenRTB Image Asset Type (OpenRTB Native Spec 7.7),
}
export enum EnumVastProtocol {
  vast1 = 'vast1', // Vast protocol (if applicable)
  vast2 = 'vast2', // Vast protocol (if applicable)
  vast3 = 'vast3', // Vast protocol (if applicable)
  vast1Wrapper = 'vast1Wrapper', // Vast protocol (if applicable)
  vast2Wrapper = 'vast2Wrapper', // Vast protocol (if applicable)
  vast3Wrapper = 'vast3Wrapper', // Vast protocol (if applicable),
}

export enum EnumCampaignState {
  active = 'active', // NO DESCRIPTION
  paused = 'paused', // NO DESCRIPTION
  pausedByRevenueCap = 'pausedByRevenueCap', // NO DESCRIPTION
}
export enum EnumCampaignStatus {
  active = 'active', // NO DESCRIPTION
  archived = 'archived', // NO DESCRIPTION
  paused = 'paused', // NO DESCRIPTION
  pausedByRevenueCap = 'pausedByRevenueCap', // NO DESCRIPTION
}
export enum EnumEmailVerificationStatus {
  nonConfirmed = 'nonConfirmed', // Email verification status
  confirmed = 'confirmed', // Email verification status,
}

export enum EnumUserRole {
  administrator = 'administrator', // User role
  buyer = 'buyer', // User role
  rekl = 'rekl', // User role,
}

export enum EnumTargetingContainerState {
  notSpecified = 'notSpecified', // State
  whitelist = 'whitelist', // State
  blacklist = 'blacklist', // State,
}

export enum EnumDeviceType {
  all = 'all', // Device types (only All, Phone and Tablet are significant)
  phone = 'phone', // Device types (only All, Phone and Tablet are significant)
  tablet = 'tablet', // Device types (only All, Phone and Tablet are significant)

  oldMobile = 'oldMobile', // Device types (only All, Phone and Tablet are significant)
  oldPersonalComputer = 'oldPersonalComputer', // Device types (only All, Phone and Tablet are significant)
  oldConnectedTV = 'oldConnectedTV', // Device types (only All, Phone and Tablet are significant)
  connectedDevice = 'connectedDevice', // Device types (only All, Phone and Tablet are significant)
  setTopBox = 'setTopBox', // Device types (only All, Phone and Tablet are significant),
}

export enum EnumProductInitialAttributionType {
  UserAcquisition = 'userAcquisition',
  RetargetingAndReengagement = 'retargetingAndReengagement',
}

export enum EnumInitialAttributionTypeList {
  originalInstall = 'originalInstall',
  reattribution = 'reattribution',
  reengagement = 'reengagement',
}

export enum EnumName {
  operationSystem = 'operationSystem', // Name
  operationSystemVersion = 'operationSystemVersion', // Name
  deviceType = 'deviceType', // Name
  make = 'make', // Name
  model = 'model', // Name
  application = 'application', // Name
  creativeID = 'creativeID', // Name
  country = 'country', // Name
  city = 'city', // Name
  hour = 'hour', // Name
  exchange = 'exchange', // Name
  day = 'day', // Name
  roasPurchases = 'roasPurchases', // Name
  exchangeCategory = 'exchangeCategory', // Name
  advertiserID = 'advertiserID', // Name
  predictorProbability = 'predictorProbability', // Name
  impPos1h = 'impPos1h', // Name
  impPos3h = 'impPos3h', // Name
  impPos12h = 'impPos12h', // Name
  impPos1d = 'impPos1d', // Name
  impPos7d = 'impPos7d', // Name
  lat = 'lat', // Name
  lon = 'lon', // Name
  isNewDevice = 'isNewDevice', // Name
  gender = 'gender', // Name
  yob = 'yob', // Name
  campaignID = 'campaignID', // Name
  region = 'region', // Name
  placementID = 'placementID', // Name
  campaignName = 'campaignName', // Name
  carrier = 'carrier', // Name
  hwVer = 'hwVer', // Name
  rawPrice = 'rawPrice', // Name
  impressionDay = 'impressionDay', // Name
  impressionHour = 'impressionHour', // Name
  impressionWeek = 'impressionWeek',
  impressionMonth = 'impressionMonth',
  convDay = 'convDay', // Name
  convHour = 'convHour', // Name
  hasIFA = 'hasIFA', // Name
  roundedBidFloor = 'roundedBidFloor', // Name
  conversions = 'conversions', // Name
  dspSpend = 'dspSpend', // Name
  bidCPM = 'bidCPM', // Name
  impressions = 'impressions', // Name
  clicks = 'clicks', // Name
  allClicks = 'allClicks', // Name
  ctr = 'ctr', // Name
  ecpm = 'ecpm', // Name
  cpi = 'cpi', // Name
  ecpc = 'ecpc', // Name
  cr = 'cr', // Name
  organizationID = 'organizationID', // Name
  advSpend = 'advSpend', // Name
  dspProfit = 'dspProfit', // Name
  dspRoi = 'dspRoi', // Name
  hourPace = 'hourPace', // Name
  i2C = 'i2C', // Name
  roas = 'roas', // Name
  adRoas = 'adRoas', // Name
  events = 'events', // Name'
  retentionRate = 'retentionRate', // Sorting key
  retentionRateEvents = 'retentionRateEvents', // Sorting key
  retentionRateInstalls = 'retentionRateInstalls', // Name
  storeID = 'storeID', // Sorting key  storeID = 'storeID', // Name
  productID = 'productID', // Name
  creativeUrl = 'creativeUrl', // Name
  currency = 'currency', // Name
  appName = 'appName', // Name
  postClicks = 'postClicks', // Name
  skanInstalls = 'skanInstalls', // Name
  postViews = 'postViews', // Name
  dealID = 'dealID', // DealID
  hasPMP = 'hasPMP', // Has PMP
  losses = 'losses', // NO DESCRIPTION
  impToLosses = 'impToLosses', // NO DESCRIPTION
  organization = 'organization', // NO DESCRIPTION
  deviceAgeDays = 'deviceAgeDays', // NO DESCRIPTION
  deviceAgeHours = 'deviceAgeHours', // NO DESCRIPTION
  deviceAgeDecay = 'deviceAgeDecay', // NO DESCRIPTION
  applicationName = 'applicationName', // NO DESCRIPTION

  goal1 = 'goal1', // Name
  goal2 = 'goal2', // Name
  goal3 = 'goal3', // Name
  goal4 = 'goal4', // Name
  goal5 = 'goal5', // Name
  goal6 = 'goal6', // Name
  ecpa1 = 'ecpa1', // Name
  ecpa2 = 'ecpa2', // Name
  ecpa3 = 'ecpa3', // Name
  ecpa4 = 'ecpa4', // Name
  ecpa5 = 'ecpa5', // Name
  ecpa6 = 'ecpa6', // Name

  purchases = 'purchases', // Name
  purchasesCount = 'purchasesCount', // Name
  winRate = 'winRate', // Name
  bids = 'bids', // Name
  uniqImpressions = 'uniqImpressions', // Name
  uniqImpRate = 'uniqImpRate', // Name
  uniqClicks = 'uniqClicks', // Name
  uniqClickRate = 'uniqClickRate', // Name
  rejects = 'rejects', // Name
  createdAt = 'createdAt', // Name
  budget = 'budget', // Name
  totalDspSpend = 'totalDspSpend', // Name,

  connectionType = 'connectionType', // Name
  rewarded = 'rewarded', // Name
  weekDay = 'weekDay', // Name
  impWidth = 'impWidth', // Name
  impHeight = 'impHeight', // Name

  i2G = 'i2G', // Name
  bti = 'bti', // Name

  hourActivityRatio = 'hourActivityRatio',
  btit = 'btit',
  overPrice = 'overPrice',

  targetStatsMap = 'targetStatsMap',
  skadNetworkMap = 'skadNetworkMap'
}

export enum EnumAdType {
  b = 'b', // banner
  n = 'n', // native
  v = 'v', // video
}

export enum EnumGroupingKey {
  adBundle = 'adBundle',
  interstitial = 'interstitial',
  month = 'month',
  application = 'application',
  language = 'language',
  impressionDay = 'impressionDay',
  impressionHour = 'impressionHour',
  convDay = 'convDay',
  campaignID = 'campaignID',
  carrier = 'carrier',
  city = 'city',
  country = 'country',
  creativeID = 'creativeID',
  day = 'day',
  deviceType = 'deviceType',
  exchange = 'exchange',
  hasIFA = 'hasIFA',
  hour = 'hour',
  hwVer = 'hwVer',
  model = 'model',
  operationSystem = 'operationSystem',
  operationSystemVersion = 'operationSystemVersion',
  placementID = 'placementID',
  rawPrice = 'rawPrice',
  region = 'region',
  roundedBidFloor = 'roundedBidFloor',
  make = 'make',
  creativeHash = 'creativeHash',
  exchangeCategory = 'exchangeCategory',
  predictorProbability = 'predictorProbability',
  impPos1h = 'impPos1h',
  impPos3h = 'impPos3h',
  impPos12h = 'impPos12h',
  impPos1d = 'impPos1d',
  impPos7d = 'impPos7d',
  lat = 'lat',
  lon = 'lon',
  isNewDevice = 'isNewDevice',
  gender = 'gender',
  roasPurchases = 'roasPurchases',
  advertiserID = 'advertiserID',
  yob = 'yob',
  connectionType = 'connectionType', // Sorting key
  rewarded = 'rewarded', // Sorting key
  weekDay = 'weekDay', // Sorting key
  impWidth = 'impWidth', // Sorting key
  impHeight = 'impHeight', // Sorting key
  target = 'target', // Sorting key
  convHour = 'convHour', // Sorting key
  adType = 'adType', // Sorting key
  dealID = 'dealID', // NO DESCRIPTION
  hasPMP = 'hasPMP', // NO DESCRIPTION
  losses = 'losses', // NO DESCRIPTION
  impToLosses = 'impToLosses', // NO DESCRIPTION
  advertiserProductName = 'advertiserProductName', // NO DESCRIPTION
  deviceAgeDays = 'deviceAgeDays', // NO DESCRIPTION
  deviceAgeHours = 'deviceAgeHours', // NO DESCRIPTION
  deviceAgeDecay = 'deviceAgeDecay', // NO DESCRIPTION
  ipSubnetwork = 'ipSubnetwork',
  ipWideNetwork = 'ipWideNetwork',
}

export enum EnumFilterEntityType {
  Equals = 'equals',
  GreaterThan = 'greaterThan',
  LessThan = 'lessThan',
  In = 'in',
  Like = 'like',
  GreaterOrEqualsThan = 'greaterOrEqualsThan',
  LessOrEqualsThan = 'lessOrEqualsThan',
}

/** IAB category description */
export interface IabCategoryDto {
  /** ID */
  categoryID: string;
  /** Title */
  title: string;
}

export interface UserDto {
  /** First name */
  firstName: string;
  /** Last name */
  lastName: string;
  /** Email */
  email: string;
  userRole: EnumUserRole;
  emailVerificationStatus: EnumEmailVerificationStatus;
  /**
   * User organization ID
   * @format int32
   */
  organizationID: number;
  /** User organization ID list */
  organizationIDList: number[];
  /** Is user active */
  isActive: boolean;
  /** User timezone */
  timeZone: string;
  /** User permissions data */
  permissionList: EnumUserPermissionDto[];
  /**
   * User ID
   * @format int32
   */
  userID: number;
}
export enum EnumUserPermissionDto {
  MarkupEdit = 'markupEdit',
  ProductEdit = 'productEdit',
  TrackingEdit = 'trackingEdit',
  CampaignCreate = 'campaignCreate',
  Clone = 'clone',
  AppsflyerTokenRetrieve = 'appsflyerTokenRetrieve',
  AdvertiserEdit = 'advertiserEdit',
  AdvertiserShare = 'advertiserShare',
  ResourceUpload = 'resourceUpload',
  SegmentUpload = 'segmentUpload',
  MarkupTest = 'markupTest',
  CampaignEdit = 'campaignEdit',
  CreativeEdit = 'creativeEdit',
  CreativeCreate = 'creativeCreate',
  PredictorEdit = 'predictorEdit',
  MParticleTokenRetrieve = 'mParticleTokenRetrieve',
  SubuserManagement = 'subuserManagement',
  AdminManagement = 'adminManagement',
  OrganizationManagement = 'organizationManagement',
  ManageAnyCampaign = 'manageAnyCampaign',
  OrganizationUserManagement = 'organizationUserManagement',
  Billing = 'billing',
  CreativeApprove = 'creativeApprove',
  CampaignSettingsView = 'campaignSettingsView',
  Pretend = 'pretend',
  GoalStatsView = 'goalStatsView',
}

export interface ProfileDto {
  /** First name */
  firstName: string;
  /** Last name */
  lastName: string;
  /** Email */
  email: string;
  userRole: EnumUserRole;
  emailVerificationStatus: EnumEmailVerificationStatus;
  /**
   * User organization ID
   * @format int32
   */
  organizationID: number;
  /** User organization ID list */
  organizationIDList: number[];
  /** Default User organization ID list */
  defaultOrganizationID?: number;
  /** Is user active */
  isActive: boolean;
  /** User timezone */
  timeZone: string;
  /** User permissions data */
  permissionList: EnumUserPermissionDto[];
  /**
   * ProfileID
   * @format int32
   */
  profileID: number;
  /** Column Order Profiles */
  columnSettings: ColumnVisibilityOrderDto[];
  /** ReklID list */
  favoriteReklIDList: number[];
}

export interface ColumnVisibilityOrderDto {
  name: string; // Settings name
  columnListTypeList: EnumColumnDataTableTypeList[]; // List of groups where this settings are applicable
  columnList: IInObjectDto<ColumnVisibilityColumnOrderDto>; // Column list settings
}

export enum EnumSessionStatus {
  verifying2FA = 'verifying2FA', // NO DESCRIPTION
  active = 'active', // NO DESCRIPTION
  disabled = 'disabled', // NO DESCRIPTION
}

/** Session object */
export interface SessionDto {
  /** Token */
  sessionToken: string;
  status: EnumSessionStatus;
  /**
   * Token expiration timestamp
   * @format date-time
   */
  expiresAt: string;
  /** Profile object information */
  profile: ProfileDto;
}

export enum EnumListType {
  exactAmount = 'exactAmount', // Type for list
  exactPercent = 'exactPercent', // Type for list
  amount = 'amount', // Type for list
  date = 'date', // Type for list
  number = 'number', // Type for list
  percent = 'percent', // Type for list
  default = 'default', // Type for list,
  bidCPM = 'bidCPM', // Type for list,
  roundedNumber = 'roundedNumber', // Type for list
  ignore = 'ignore' // Type for list,
}
export enum EnumTotalType {
  exactAmount = 'exactAmount', // Type for totals
  exactPercent = 'exactPercent', // Type for totals
  amount = 'amount', // Type for totals
  date = 'date', // Type for totals
  number = 'number', // Type for totals
  percent = 'percent', // Type for totals
  default = 'default', // Type for totals,
  bidCPM = 'bidCPM', // Type for totals,
}

export enum EnumFieldColumnListType {
  listType = 'listType',
  totalType = 'totalType',
}

export enum EnumValueType {
  floatBoolean = 'floatBoolean',
  int = 'int',
  date = 'date',
  string = 'string',
  float = 'float',
}

export interface ColumnVisibilityColumnOrderDto {
  name: string; // Name
  visibility: EnumVisibility;
  order: number; // Order number

  allowGrouping: boolean; // Can group by this column
  allowOrdering: boolean; // Can order by this column
  allowFiltering: boolean; // Can filter by this column

  listType: EnumListType;
  valueType: EnumValueType;
  totalType: EnumTotalType;
}

export interface History {
  push: (e: any) => void;
  block: (e: any) => any;
}

/** Country object */
export interface CountryDto {
  /** ISO-3 Code */
  countryID: string;
  /** Name */
  title: string;
  /** Geographical region */
  region: string;
}

export enum EnumConnectionType {
  unknown = 'unknown',
  ethernet = 'ethernet',
  wifi = 'wifi',
  cellularNetworkUnknownGeneration = 'cellularNetworkUnknownGeneration',
  cellularNetwork2G = 'cellularNetwork2G',
  cellularNetwork3G = 'cellularNetwork3G',
  cellularNetwork4G = 'cellularNetwork4G',
}

export type DefaultTargetingState = {
  content: string[];
  targetingContainerState: EnumTargetingContainerState;
};

/** Installed applications group */
export interface InstalledAppGroupDto {
  /**
   * Group ID
   * @format int32
   */
  appGroupID: number;
  /**
   * Minimum installed applications
   * @format int32
   */
  minInstalled: number;
  /** Name of the group */
  name: string;
  /** Application list */
  applicationList: string[];
}

/** Installed by campaigns */
export interface TargetingInstalledByDto {
  /**
   * ID
   * @format int32
   */
  targetingInstalledByID: number;
  /**
   * Installed By Campaign ID
   * @format int32
   */
  installedByCampaignID: number;
  /**
   * Seconds interval for retarget
   * @format int32
   */
  seconds: number;
}

/** Day of week & hour of day settings */
export interface TargetingWeekHourDto {
  /**
   * ID
   * @format int32
   */
  targetingWeekHourID: number;
  /**
   * Day of week 0-6. 0 is Sunday
   * @format int32
   */
  dayOfWeek: number;
  /**
   * Hour of day 0-23
   * @format int32
   */
  hourOfDay: number;
}

export enum EnumOrganizationStatsType {
  advertiser = 'advertiser',
  country = 'country',
  genre = 'genre',
  office = 'office',
  exchange = 'exchange',
  division = 'division',
  organization = 'organization'
}

export enum EnumNullableBoolean {
  nulled = 'nulled',
  enabled = 'enabled',
  disabled = 'disabled',
}
export enum EnumGenreLevel {
  low = 'low', // NO DESCRIPTION
  medium = 'medium', // NO DESCRIPTION
  high = 'high', // NO DESCRIPTION
}

/** Targeting genre dto */
export interface TargetingGenreDto {
  /**
   * Genre ID
   * @format int32
   */
  genreID: number;
  level: EnumGenreLevel;
}

export interface TargetingDto {
  coppaType: EnumNullableBoolean; // COPPA (true - yes, false - all without coppa, null - doesn't matter)
  frequencyImpTimes: number; // Frequency for impressions impressions
  maxImpPosition: number; // Maximum available impression position
  maxImpPosLength: number; // Maximum available impression period in seconds
  hourlyPaceLimit: number; // Maximum spend in 1 hour
  rewarded: EnumNullableBoolean; // Should company target Rewarded / NonRewarded / All traffic
  hasIfa: EnumNullableBoolean; // Should company target IFA / LAT traffic
  osVersionMin: string; // Minimum operation system version ( (\d+\.)*\d+ )
  osVersionMax: string; // Maximum operation system version ( (\d+\.)*\d+ )
  minAge: number; // Minimum age
  maxAge: number; // Maximum age
  daysAgoMin: number; // Day ago min
  daysAgoMax: number; // Day ago max
  startDate: string; // Start date
  stopDate: string; // Stop date
  gender: EnumGender; // Maximum age
  allowInstalled: boolean; // Retargeting settings
  skadNetwork: EnumNullableBoolean; // Allow skad network response (true - yes, false - no, null - doesn't matter
  dealIDList: string[]; // Private Deal ID
  deviceType: EnumDeviceType;
  appNameKeywords: any; // App keywords list (WL/BL)
  segmentList: {
    content: number;
    targetingContainerState: EnumTargetingContainerState;
  }[]; // Segments list information (List of WL / BL)

  installedByList: TargetingInstalledByDto[]; // Installed by targeting

  genreList: {
    content: TargetingGenreDto[];
    targetingContainerState: EnumTargetingContainerState;
  }; // Installed application list (WL/BL)

  installedApplicationGroupWhiteList: {
    content: InstalledAppGroupDto[];
    targetingContainerState: EnumTargetingContainerState;
  }; // Installed application list (WL/BL)
  storeCategoryList: DefaultTargetingState; // Application store categories (WL)
  modelList: {
    content: TargetingDeviceModelDto[];
    targetingContainerState: EnumTargetingContainerState;
  }; // Device model list (WL/BL)
  connectionTypeList: {
    content: TargetingConnectionTypeDto[];
    targetingContainerState: EnumTargetingContainerState;
  }; // Selection connection type (OpenRTB2.5) (WL)
  makeList: {
    content: TargetingDeviceMakeDto[];
    targetingContainerState: EnumTargetingContainerState;
  }; // Device make list (WL/BL)
  bundleGenreList: {
    content: TargetingBundleGenreDto[];
    targetingContainerState: EnumTargetingContainerState;
  }; // Carrier list (WL/BL)
  applicationList: {
    content: TargetingApplicationDto[];
    targetingContainerState: EnumTargetingContainerState;
  }; // Application list (WL/BL)
  carrierList: {
    content: TargetingCarrierDto[];
    targetingContainerState: EnumTargetingContainerState;
  }; // Carrier list (WL/BL)
  countryList: {
    content: TargetingCountryDto[];
    targetingContainerState: EnumTargetingContainerState;
  }; // Countries list (WL)
  exchangeList: {
    content: TargetingExchangeDto[];
    targetingContainerState: EnumTargetingContainerState;
  }; // Exchange list (WL)
  iabCategoryList: {
    content: TargetingIABCategoryDto[];
    targetingContainerState: EnumTargetingContainerState;
  }; // IAB Category List (WL)
  installedApplicationBlackList: {
    content: TargetingInstalledApplicationBlackDto[];
    targetingContainerState: EnumTargetingContainerState;
  }; // Application list (WL/BL)
  languageList: {
    content: TargetingLanguageDto[];
    targetingContainerState: EnumTargetingContainerState;
  }; // Language list (WL)
  operationSystemVersionList: {
    content: TargetingOperationSystemVersionDto[];
    targetingContainerState: EnumTargetingContainerState;
  }; // Operation system version list (WL/BL)
  osList: {
    content: TargetingOSDto[];
    targetingContainerState: EnumTargetingContainerState;
  }; // OS list (WL/BL)
  regionList: {
    content: TargetingRegionDto[];
    targetingContainerState: EnumTargetingContainerState;
  }; // Region list (WL/BL)
  sspList: {
    content: TargetingSspDto[];
    targetingContainerState: EnumTargetingContainerState;
  }; // Exchange list (WL)
  userAgentList: {
    content: TargetingUserAgentDto[];
    targetingContainerState: EnumTargetingContainerState;
  }; // User agent list (WL)
  cityList: {
    content: TargetingCityDto[];
    targetingContainerState: EnumTargetingContainerState;
  }; // City list (WL/BL)
  weekHourList: TargetingWeekHourDto[]; // Week hour list
}

export interface TrackerLinkBaseUrlParam {
  key: string;
  description: string;
  name: string;
}
export interface TrackerLinkQueryParam {
  key: string;
  defaultValue: string;
  required: boolean;
  editable: boolean;
  description: string;
  name: string;
  os: EnumOperationSystem[];
  linkType: EnumTrackerLinkType[];
  callType: EnumTrackerCallType[];
}

export interface TrackerLinkBaseUrl {
  url: string;
  params: TrackerLinkBaseUrlParam[];
  os: EnumOperationSystem[];
  linkType: EnumTrackerLinkType[];
  callType: EnumTrackerCallType[];
}

export interface TrackerLinkConfig {
  baseUrls: TrackerLinkBaseUrl[];
  queryParams: TrackerLinkQueryParam[];
}
export interface TrackerDto {
  /**
   * Tracker ID
   * @format int32
   */
  trackerID: number;
  /** Skad Tracker ID */
  hasSkad: boolean;
  /** Enabled bool */
  enabled: boolean;
  /** Tracker name */
  name: string;
  /** Support only redirect links */
  onlyRedirect: boolean;
  linkConfig: TrackerLinkConfig;
}

/** Genre object */
export interface GenreDto {
  /**
   * Genre 48 index
   * @format int32
   */
  genreID: number;
  /** Name */
  name: string;
}

/** Rekl bundle link */
export interface ReklBundleDto {
  /**
   * Rekl Bundle ID
   * @format int32
   */
  reklBundleID: number;
  /**
   * Rekl ID
   * @format int32
   */
  reklID: number;
  /**
   * Bundle ID
   * @format int32
   */
  bundleID: number;
  /** Appsflyer api token */
  appsflyerApiToken: string;
  /** MParticle api token */
  mParticleApiToken: string;
  /** Notes */
  notes: string;
  /** Allow in lite-ui */
  allowInLiteUI: boolean;
  /** Product ID List */
  productIDList: number[];
}

/** Address information */
export interface AddressDto {
  /** ZIP */
  zipCode: string;
  /** Country */
  country: string;
  /** Region */
  province: string;
  /** City */
  city: string;
  /** Address first line */
  address1: string;
  /** Address second line */
  address2: string;
}

export enum EnumOrganizationPaymentSource {
  stripe = 'stripe', // NO DESCRIPTION
  // automaticAdjustment = 'automaticAdjustment',
  creditLinkedNote = 'creditLinkedNote', // NO DESCRIPTION
}

export enum EnumOrganizationStatus {
  active = 'active', // NO DESCRIPTION
  blocked = 'blocked', // NO DESCRIPTION
}

/** Organization data */
export interface OrganizationDto {
  /**
   * Organization ID
   * @format int32
   */
  organizationID: number;
  /** Name */
  name: string;
  /** Address information */
  billingAddress: AddressDto;
  /** Phone number */
  phone: string;
  /** Organization type */
  legalEntity: keyof typeof EnumLegalEntity;
  /** Tax identifier */
  tin: string;
  /** Postback url */
  postbackUrl: string;
  /** Organization can participate in bidding */
  isBiddingActive: boolean;
  status: EnumOrganizationStatus;
  /**
   * Current payment balance
   * @format double
   */
  currency: 'RUB' | 'USD';
  balance: number;
  /** Organization needs its creatives to be approved */
  isApproveCreatives: boolean;
  /** Organization can setup video size */
  isSetupVideoSizeAllowed: boolean;
  /** Organization permissions data */
  permissionList: EnumUserPermissionDto[];
}

export enum EnumOrganizationPaymentStatus {
  inProcess = 'inProcess', // NO DESCRIPTION
  confirmed = 'confirmed', // NO DESCRIPTION
  declined = 'declined', // NO DESCRIPTION
}

/** Organization payment data */
export interface OrganizationPaymentDto {
  /**
   * Payment ID
   * @format int32
   */
  organizationPaymentID: number;
  /**
   * Organization ID
   * @format int32
   */
  organizationID: number;
  /**
   * Payment amount
   * @format int32
   */
  amountUSD: number;
  /** Message */
  data: string;
  /**
   * Message
   * @format date-time
   */
  completedAt: string;
  source: EnumOrganizationPaymentSource;
  status: EnumOrganizationPaymentStatus;
}

export interface Pagination {
  page: number;
  count: number;
  total: number;
}

/** Goal stats list entity */
export interface GoalStatsListDto {
  /** Goal ID */
  goalID: string;
  /**
   * Count
   * @format int32
   */
  count: number;
  /**
   * UniqIFA
   * @format int32
   */
  uniqIFA: number;
}

/** Goal description */
export interface GoalDto {
  /**
   * GoalID
   * @format int32
   */
  goalID: number;
  /**
   * Position
   * @format int32
   */
  position: number;
  /** Goal code */
  code: string;
  /**
   * CPA Payout
   * @format double
   */
  payout: number;
  /**
   * Revenue Share coefficient
   * @format double
   */
  revenueShare: number;
  /**
   * Target value for Goal
   * @format double
   */
  target: number;
  /** Payable only for unique event */
  payableUnique: boolean;
  /**
   * How many days after conversion event marked as payable
   * @format double
   */
  payableDuration: number;
  /** Is this goal important (CPA) */
  isImportant: boolean;
}

export interface TimeZoneDto {
  timeZoneID: string; // Time zone ID
  name: string; // Time zone name
  offset: string; // Offset
}

export interface CampaignStatsDto {
  [EnumStatsColumnName.createdAt]: string; // Campaign created date time
  [EnumStatsColumnName.totalDspSpend]: number; // Spend for all campaign time
  [EnumStatsColumnName.budget]: number; // Campaign budget
  [EnumStatsColumnName.dspSpend]: number; // Spend for selected time period
  [EnumStatsColumnName.impressions]: number; // Impressions
  [EnumStatsColumnName.losses]: number; // Losses
  [EnumStatsColumnName.clicks]: number; // Clicks
  [EnumStatsColumnName.allClicks]: number; // AllClicks
  [EnumStatsColumnName.conversions]: number; // Conversions
  [EnumStatsColumnName.postViews]: number; // Amount of conversions by impression
  [EnumStatsColumnName.postClicks]: number; // Amount of conversions by click
  [EnumStatsColumnName.rejects]: number; // Rejects
  [EnumStatsColumnName.ctr]: number; // CTR
  [EnumStatsColumnName.impToLosses]: number; // Impressions to losses ratio
  [EnumStatsColumnName.bidCPM]: number; // Average bidCPM
  [EnumStatsColumnName.bidFloor]: number; // Average bidFloor for bids
  [EnumStatsColumnName.ecpm]: number; // ECPM (spend for 1000 impressions)
  [EnumStatsColumnName.cpi]: number; // CPI
  [EnumStatsColumnName.ecpc]: number; // ECPC (spend for click)
  [EnumStatsColumnName.i2C]: number; // I2C (impression to clicks)
  [EnumStatsColumnName.i2G]: number; // I2G (impression to goals)
  [EnumStatsColumnName.bti]: number; // BTI (bids to impression)
  [EnumStatsColumnName.roas]: number; // ROAS
  [EnumStatsColumnName.adRoas]: number; // Ad ROAS
  [EnumStatsColumnName.retentionRateEvents]: number; // Events for calculate Retention Rate (numerator)
  [EnumStatsColumnName.retentionRateInstalls]: number; // Installs for calculate Retention Rate (denominator)
  [EnumStatsColumnName.retentionRate]: number; // Retention Rate
  [EnumStatsColumnName.cr]: number; // CR (click ratio)
  [EnumStatsColumnName.advSpend]: number; // Advertiser spend for agencies model
  [EnumStatsColumnName.roasPurchases]: number; // Roas days sum purchases
  [EnumStatsColumnName.purchases]: number; // Total purchases sum
  [EnumStatsColumnName.purchasesCount]: number; // Total purchases count
  [EnumStatsColumnName.importantGoals]: number; // Important goals count (metric)
  [EnumStatsColumnName.goal1]: number; // Events for goals 1
  [EnumStatsColumnName.goal2]: number; // Events for goals 2
  [EnumStatsColumnName.goal3]: number; // Events for goals 3
  [EnumStatsColumnName.goal4]: number; // Events for goals 4
  [EnumStatsColumnName.goal5]: number; // Events for goals 5
  [EnumStatsColumnName.goal6]: number; // Events for goals 6
  [EnumStatsColumnName.ecpa1]: number; // eCPA for goal 1
  [EnumStatsColumnName.ecpa2]: number; // eCPA for goal 2
  [EnumStatsColumnName.ecpa3]: number; // eCPA for goal 3
  [EnumStatsColumnName.ecpa4]: number; // eCPA for goal 4
  [EnumStatsColumnName.ecpa5]: number; // eCPA for goal 5
  [EnumStatsColumnName.ecpa6]: number; // eCPA for goal 6
  [EnumStatsColumnName.dspProfit]: number; // Profit
  [EnumStatsColumnName.dspRoi]: number; // ROI
  [EnumStatsColumnName.hourPace]: number; // Hour Pace (spending over hour)
  /**
 * Bids to impressions ratio
 * @format double
 */
  [EnumStatsColumnName.winRate]: number;
  [EnumStatsColumnName.uniqImpRate]: number; // Unique impressions rate
  [EnumStatsColumnName.uniqImpressions]: number; // Unique impressions ratio
  [EnumStatsColumnName.bids]: number; // Bids count
  [EnumStatsColumnName.events]: number; // Event count
  [EnumStatsColumnName.uniqClicks]: number; // Unique devices where clicks have been
  [EnumStatsColumnName.uniqClickRate]: number; // Clicks / UniqClicks
  [EnumStatsColumnName.campaignID]: number; // Campaign ID
  [EnumStatsColumnName.application]: string; // Application bundle (in grouping)
  [EnumStatsColumnName.city]: string; // City (in grouping)
  [EnumStatsColumnName.region]: string; // Region (in grouping)
  [EnumStatsColumnName.placementID]: string; // Ad placement ID (in grouping)
  [EnumStatsColumnName.carrier]: string; // Carrier (in grouping)
  [EnumStatsColumnName.hwVer]: string; // Hardware Version (in grouping)
  [EnumStatsColumnName.country]: string; // Country (in grouping)
  [EnumStatsColumnName.day]: string; // Day (in grouping)
  [EnumStatsColumnName.exchangeCategory]: string; // Category as it's returned from exchange (in grouping)
  [EnumStatsColumnName.creativeID]: number; // Creative ID (in grouping)
  [EnumStatsColumnName.exchange]: string; // Exchange (in grouping)
  [EnumStatsColumnName.hour]: number; // Hour of event (in grouping)
  [EnumStatsColumnName.impressionDay]: string; // Impression day (in grouping)
  [EnumStatsColumnName.impressionHour]: number; // Hour of impression (in grouping)
  [EnumStatsColumnName.convDay]: string; // Conversion day (in grouping)
  [EnumStatsColumnName.convHour]: number; // Hour of Conversion (in grouping)
  [EnumStatsColumnName.model]: string; // Device model (in grouping)
  [EnumStatsColumnName.make]: string; // Device make (in grouping)
  [EnumStatsColumnName.deviceType]: number; // Device type (in grouping)
  [EnumStatsColumnName.operationSystem]: string; // Operation system (in grouping)
  [EnumStatsColumnName.organization]: string; // Organization
  [EnumStatsColumnName.operationSystemVersion]: string; // Operation system version (in grouping)
  [EnumStatsColumnName.rawPrice]: number; // Average bidCPM (in grouping)
  [EnumStatsColumnName.gender]: string; // Gender (in grouping)
  [EnumStatsColumnName.yob]: number; // Year of birth (in grouping)
  [EnumStatsColumnName.impPos1h]: number; // Impression Position 1h (in grouping)
  [EnumStatsColumnName.impPos3h]: number; // Impression Position 3h (in grouping)
  [EnumStatsColumnName.impPos12h]: number; // Impression Position 12h (in grouping)
  [EnumStatsColumnName.impPos1d]: number; // Impression Position 1d (in grouping)
  [EnumStatsColumnName.impPos7d]: number; // Impression Position 7d (in grouping)
  [EnumStatsColumnName.lat]: number; // Latitude (precision is 0.1) (in grouping)
  [EnumStatsColumnName.lon]: number; // Longitude (precision is 0.1) (in grouping)
  [EnumStatsColumnName.creativeHash]: string; // Creative hash
  [EnumStatsColumnName.productID]: number; // Product ID
  [EnumStatsColumnName.organizationID]: number; // Organization ID
  [EnumStatsColumnName.advertiserID]: number; // Advertiser ID
  [EnumStatsColumnName.connectionType]: EnumConnectionType; // NO DESCRIPTION
  [EnumStatsColumnName.rewarded]: number; // Rewarded
  [EnumStatsColumnName.dealID]: string; // DealID
  [EnumStatsColumnName.hasPMP]: number; // Has PMP
  [EnumStatsColumnName.weekDay]: keyof typeof EnumWeekDay; // Day of week
  [EnumStatsColumnName.skanInstalls]: number; // Amount of skan installs
  [EnumStatsColumnName.impWidth]: number; // Impression width
  [EnumStatsColumnName.impHeight]: number; // Impression width
  [EnumStatsColumnName.currency]: string; // Currency
  [EnumStatsColumnName.appName]: string; // Application name (Singular)
  [EnumStatsColumnName.applicationName]: string; // Application name
  [EnumStatsColumnName.campaignName]: string; // Campaign name
  [EnumStatsColumnName.advertiserProductName]: string; // Product name
  [EnumStatsColumnName.storeID]: string; // Application store id
  [EnumStatsColumnName.creativeUrl]: string; // Url to creative preview
  [EnumStatsColumnName.creativeName]: string; // Name of creative
  [EnumStatsColumnName.roundedBidFloor]: number; // Rounded bidFloor (in grouping)
  [EnumStatsColumnName.hasIFA]: number; // IFA / no IFA (in grouping)
  [EnumStatsColumnName.timeSeriesAt]: string; // TimeSeriesAt (in grouping)
  [EnumStatsColumnName.target]: string; // Target (in grouping)
  [EnumStatsColumnName.predictorProbability]: number; // Predictor adjusted probability
  [EnumStatsColumnName.adType]: EnumAdType; // NO DESCRIPTION
  [EnumStatsColumnName.adBundle]: string; // Target bundle (in grouping)
  [EnumStatsColumnName.deviceAgeDays]: number; // Device age (in grouping)
  [EnumStatsColumnName.deviceAgeHours]: number; // Device age hours (in grouping)
  [EnumStatsColumnName.deviceAgeDecay]: number; // Device age decay (in grouping)
  [EnumStatsColumnName.interstitial]: number; // Interstitial
  [EnumStatsColumnName.ipSubnetwork]: string;
  [EnumStatsColumnName.ipWideNetwork]: string; // ipWideNetwork
  [EnumStatsColumnName.hourActivityRatio]: number;
  [EnumStatsColumnName.btit]: number;
  [EnumStatsColumnName.overPrice]: number;
  /**
   * Impression to (impressions plus losses) rate (impression to (impressions + losses by outbid))
   * @format double
   */
  [EnumStatsColumnName.impWinRate]: number;
  [EnumStatsColumnName.targetStatsMap]: Record<string, number>;
  [EnumStatsColumnName.skadNetworkMap]: Record<string, number>;
  // [EnumStatsColumnName.dynamicProperties]: string; // Dynamic properties (in grouping)
  // [EnumStatsColumnName.reportingKeyColumn]: string; // Reporting key column
}

/** Creative file information */
export interface CreativeFileDto {
  /**
   * Duration (if applicable)
   * @format int32
   */
  duration: number;
  /**
   * Hieght (if applicable)
   * @format int32
   */
  height: number;
  /**
   * File with (if applicable)
   * @format int32
   */
  width: number;
}

/** Information about creative hash status on different exchanges */
export interface CreativeExchangeStatusDto {
  /** Exchange */
  exchange: string;
  /** Error code list */
  errorCodeList: number[];
}

/** Creative exchange specific information */
export interface CreativeExchangeDto {
  /** List of statuses for the exchanges */
  statusList: CreativeExchangeStatusDto[];
}

export enum EnumCreativeViewabilityProvider {
  ignore = 'ignore', // NO DESCRIPTION
  none = 'none', // NO DESCRIPTION
  any = 'any', // NO DESCRIPTION
  moat = 'moat', // NO DESCRIPTION
  ias = 'ias', // NO DESCRIPTION
}
export enum EnumCreativeOrientation {
  none = 'none', // NO DESCRIPTION
  landscape = 'landscape', // NO DESCRIPTION
  portrait = 'portrait', // NO DESCRIPTION
}

export enum EnumCreativeApproveStatus {
  onReview = 'onReview', // NO DESCRIPTION
  rejected = 'rejected', // NO DESCRIPTION
  approved = 'approved', // NO DESCRIPTION
}

/** Creative last approve request */
export interface CreativeApproveRequestDto {
  /**
   * Requested At
   * @format date-time
   */
  requestedAt: string;
  /**
   * Reviewed At
   * @format date-time
   */
  reviewedAt: string;
  /** Message */
  message: string;
  approveStatus: EnumCreativeApproveStatus;
}

/** Creative approve request list request dto */
export interface CreativeApproveRequestListRequestDto {
  /** Client request ID */
  requestID: string;
  /** Client version */
  clientVersion: string;
  /** Custom result list */
  responseResultList: EnumResponseListType[];
  /** Token */
  sessionToken: string;
  /** Admin session token */
  adminSessionToken: string;
  /** Use rekl figures layer */
  useReklFigures: boolean;
  /** Are request dates in UTC already */
  requestTzUtc: boolean;
  /** Include approved or rejected requests */
  includeProcessed: boolean;
  /**
   * Max known request at
   * @format date-time
   */
  maxRequestedAt: string;
}

/** Creative approve request dto */
export interface CreativeApproveRequestRequestDto {
  /** Client request ID */
  requestID: string;
  /** Client version */
  clientVersion: string;
  /** Custom result list */
  responseResultList: EnumResponseListType[];
  /** Token */
  sessionToken: string;
  /** Admin session token */
  adminSessionToken: string;
  /** Use rekl figures layer */
  useReklFigures: boolean;
  /** Are request dates in UTC already */
  requestTzUtc: boolean;
  /**
   * Creative id
   * @format int32
   */
  creativeID: number;
  approveStatus: EnumCreativeApproveStatus;
  /** Review message */
  message: string;
}

export interface CreativeVariableDto {
  isNew?: boolean;
  resourceID: number; // Resource ID
  key: string; // Key
  value: string; // Value
  isRequired: boolean; // only front
  description: string; // only front
}

export interface CreativeResourceDto {
  resourceID: number; // Resource ID
  key: string; // Role (key) in markup (could be null)
}
export interface CreativeContentDto {
  creativeNative: CreativeNativeDto; // Native creative data
  markupVariableList: CreativeVariableDto[]; // Variable list for markup
  resourceList: CreativeResourceDto[]; // Resource role list
  creativeVariableList: any[]; // Variable list for markup
  markupID: number; // Mark up ID
  seed: string; // Seed (random value for hash update)
}

/** Native creative information */
export interface CreativeNativeDto {
  /** Title */
  title: string;
  /**
   * Icon image resource ID
   * @format int32
   */
  iconResourceID: number;
  /**
   * Logo image resource ID
   * @format int32
   */
  logoResourceID: number;
  /**
   * Main image resource ID
   * @format int32
   */
  mainImageResourceID: number;
  mainImageAlternativeResourceID: number;
  /** Is main image required for making a bid */
  mainImageRequired: boolean;
  /**
   * Video resource ID
   * @format int32
   */
  videoResourceID: number;
  /** Is video required for making a bid */
  videoRequired: boolean;
  /** Sponsored by text */
  sponsoredBy: string;
  /** Description text */
  description: string;
  /** Rating text */
  rating: string;
  /** Price text */
  price: string;
  /** Call to action text */
  ctaText: string;
}

/** Response container */
export interface CreativeSettingsDto {
  viewability: EnumCreativeViewabilityProvider;
  orientation: EnumCreativeOrientation;
  /** Labeling settings */
  labelSettings?: LabelSettingsDto;
}

export interface CreativeDto {
  /**
   * Creative ID
   * @format int32
   */
  creativeID: number;
  /**
   * Campaign ID
   * @format int32
   */
  campaignID: number;
  /**
   * Created at
   * @format date-time
   */
  createdAt: string;
  /**
   * Start date
   * @format date-time
   */
  shouldStartAt: string | null;
  /**
   * Stop date
   * @format date-time
   */
  shouldStopAt: string | null;
  /**
   * Updated at (necessary for update)
   * @format date-time
   */
  updatedAt: string;
  /** Exchange Hash (openrtb crid) */
  exchangeHash: string;
  /** Predictor Hash */
  predictorHash: string;
  /** Is creative disabled */
  enabled: boolean;
  /** Creative file information */
  file: CreativeFileDto;
  /** Creative exchange specific information */
  exchange: CreativeExchangeDto;
  /** Creative requirements */
  settings: CreativeSettingsDto;
  /** Creative content */
  content: CreativeContentDto;
  /** Creative last approve request */
  approveRequest: CreativeApproveRequestDto;
  /** Is allowed for requested user/api to edit */
  isAllowEditing: boolean;
  /** Creative is approved */
  isApproved: boolean;
  approveStatus: EnumCreativeApproveStatus;
}
//

/** Product Tracking information */
export interface ProductTrackingDto {
  /**
   * Tracker ID
   * @format int32
   */
  trackerID: number;
  /** Application link in store (http) */
  targetUrlHttp: string;
  /** Application link in store (deel link eg market://) */
  targetUrlMarket: string;
  /** Tracking link dto */
  impressionRedirect: TrackingLinkDto;
  /** Tracking link dto */
  clickRedirect: TrackingLinkDto;
  /** Tracking link dto */
  impressionS2S: TrackingLinkDto;
  /** Tracking link dto */
  clickS2S: TrackingLinkDto;
  useDeepLink: boolean;
  /**
   * Payout for CPM
   * @format double
   */
  payoutCPM: number;
  /**
   * Payout for CPC
   * @format double
   */
  payoutCPC: number;
  /**
   * Payout for CPI
   * @format double
   */
  payoutCPI: number;
  /**
   * SKAdNetwork tracker to which forward postbacks
   * @format int32
   */
  skadTrackerID: number;
  /** Goal list information */
  goalList: GoalDto[];
}

/** Zip dto */
export interface TargetingZipDto {
  /**
   * Zip code
   * @format int32
   */
  targetingZipID: number;
  /** Zip code */
  zip: string;
}

/** City dto */
export interface TargetingCityDto {
  /**
   * ID
   * @format int32
   */
  targetingCityID: number;
  /** Full geo path of the city */
  description: string;
  /** City name */
  name: string;
  /**
   * North East point Lat
   * @format double
   */
  north: number;
  /**
   * North East point Lng
   * @format double
   */
  east: number;
  /** Google place id (eg ChIJSW5OwfhkUocRmVRxEMt64l8) */
  placeID: string;
  /**
   * South west point Lat
   * @format double
   */
  south: number;
  /**
   * South west point Lng
   * @format double
   */
  west: number;
  /** Black list reason */
  reason: string;
}

/** Device model dto */
export interface TargetingDeviceModelDto {
  /**
   * TargetingDeviceModelID
   * @format int32
   */
  targetingDeviceModelID: number;
  /** Name */
  name: string;
}

/** Targeting connection type dto */
export interface TargetingConnectionTypeDto {
  /**
   * ID
   * @format int32
   */
  targetingConnectionTypeID: number;
  connectionType: EnumConnectionType;
}

/** Connection type */
export interface ConnectionTypeDto {
  /**
   * ID
   * @format int32
   */
  connectionTypeID: number;
  /** Name */
  name: string;
}

/** Bundle genre dto */
export interface TargetingBundleGenreDto {
  /**
   * Targeting bundle genre ID
   * @format int32
   */
  targetingBundleGenreID?: number;
  /**
   * Genre ID
   * @format int32
   */
  genreID?: number;
}

/** Application dto */
export interface TargetingApplicationDto {
  /**
   * ID
   * @format int32
   */
  targetingApplicationID: number;
  /** Application bundle name */
  name: string;
  /** Application reason */
  reason: string;
}

/** App names keywords dto */
export interface TargetingAppNamesKeywordsDto {
  /**
   * TargetingAppKeywordID
   * @format int32
   */
  targetingAppKeywordID: number;
  /** Name */
  name: string;
}

/** iab category dto */
export interface TargetingIABCategoryDto {
  /**
   * TargetingIABCategoryID
   * @format int32
   */
  targetingIABCategoryID: number;
  /** IABCategoryID */
  iabCategoryID: string;
}

/** Exchange dto */
export interface TargetingExchangeDto {
  /**
   * TargetingExchangeID
   * @format int32
   */
  targetingExchangeID: number;
  /** Code */
  code: string;
}

/** Country dto */
export interface TargetingCountryDto {
  /**
   * TargetingCountryID
   * @format int32
   */
  targetingCountryID: number;
  /** Name */
  countryID: string;
}

/** User agent dto */
export interface TargetingUserAgentDto {
  /**
   * TargetingUserAgentID
   * @format int32
   */
  targetingUserAgentID: number;
  /** Keyword */
  keyword: string;
}

export interface InstalledAppGroupDtoListStatedContainerDto {
  targetingContainerState: EnumTargetingContainerState;
  /** Content */
  content: InstalledAppGroupDto[];
}

/** Installed application dto */
export interface TargetingInstalledApplicationBlackDto {
  /**
   * TargetingAppInstalledBlackID
   * @format int32
   */
  targetingAppInstalledBlackID: number;
  /** Name */
  name: string;
}

/** Carrier dto */
export interface TargetingCarrierDto {
  /**
   * TargetingCarrierID
   * @format int32
   */
  targetingCarrierID: number;
  /** Name */
  name: string;
}

/** Region dto */
export interface TargetingRegionDto {
  /**
   * TargetingRegionID
   * @format int32
   */
  targetingRegionID: number;
  /** Name */
  name: string;
}

/** Device make dto */
export interface TargetingDeviceMakeDto {
  /**
   * TargetingDeviceMakeID
   * @format int32
   */
  targetingDeviceMakeID: number;
  /** Name */
  name: string;
}

/** Device model dto */
export interface TargetingDeviceModelDto {
  /**
   * TargetingDeviceModelID
   * @format int32
   */
  targetingDeviceModelID: number;
  /** Name */
  name: string;
}

/** Targeting OS dto */
export interface TargetingOSDto {
  /**
   * ID
   * @format int32
   */
  targetingOsID: number;
  os: EnumOperationSystem;
}

/** Opration system version dto */
export interface TargetingOperationSystemVersionDto {
  /**
   * TargetingOSVersionID
   * @format int32
   */
  targetingOSVersionID: number;
  /** Min */
  min: string;
  /** Max */
  max: string;
}

/** Ssp dto */
export interface TargetingSspDto {
  /**
   * TargetingSspID
   * @format int32
   */
  targetingSspID: number;
  /** SspID */
  sspID: string;
}

/** Language dto */
export interface TargetingLanguageDto {
  /**
   * CampaignLanguageID
   * @format int32
   */
  campaignLanguageID: number;
  /** LanguageID */
  languageID: string;
}

/** Ip dto */
export interface TargetingIpDto {
  /**
   * Id
   * @format int32
   */
  targetingIpID: number;
  /** Ip number */
  ip: string;
}

/** Non entity data container */
export interface TimeSeriesByTargetStatsDto {
  /** Target */
  target: string;
  /** Time series statistics */
  timeSeriesStats: CampaignStatsDto[];
}

export interface ProductTargetingDto {
  /**
   * Interstitial. Seconds window for 1 impression occur interstitial
   * @format int32
   */
  frequencySecondsInterstitial: number;
  /**
   * Non Interstitial. Seconds window for 1 impression occur non interstitial
   * @format int32
   */
  frequencySecondsNonInterstitial: number;
  /**
   * Seconds window for 1 click occur
   * @format int32
   */
  frequencyClickSeconds: number;
  /** Allow SKAd Network tracking */
  useOsAdFeatures: boolean;
  cityList: {
    content: TargetingCityDto[];
    targetingContainerState: EnumTargetingContainerState;
  }; // City list (WL/BL)
  applicationList: {
    content: TargetingApplicationDto[];
    targetingContainerState: EnumTargetingContainerState;
  }; // Carrier list (WL/BL)
  zipList: {
    content: TargetingZipDto[];
    targetingContainerState: EnumTargetingContainerState;
  }; // ZIP list (WL)
  ipList: {
    content: TargetingIpDto[];
    targetingContainerState: EnumTargetingContainerState;
  }; // IP list (WL)
  osList: {
    content: TargetingOSDto[];
    targetingContainerState: EnumTargetingContainerState;
  }; // OS list (WL/BL)
}

export enum EnumProductType {
  Performance = 'performance',
  Media = 'media',
}

export enum EnumProductStatus {
  active = 'active', // NO DESCRIPTION
  archived = 'archived', // NO DESCRIPTION
  paused = 'paused', // NO DESCRIPTION
}

export interface Int32OptionValue {
  /** Is value presented */
  presented?: boolean;
  /**
   * Value
   * @format int32
   */
  value?: number;
}

export interface LabelSettingsDto {
  /**
   * Key from [1-100]
   * @format int32
   */
  keyFrom?: number;
  /**
   * Key to [1-100]
   * @format int32
   */
  keyTo?: number;
  /** Label */
  label?: string | null;
  salt?: Int32OptionValue;
  /** Ghost bidding */
  ghostBidding?: boolean;
}

/** Product information */
export interface ProductDto {
  /**
   * Product ID
   * @format int32
   */
  productID: number;
  /** Name */
  name: string;
  /** Product custom tag */
  customTagList: KeyValuePairDto[];
  /**
   * Rekl bundle ID
   * @format int32
   */
  reklBundleID: number;
  /** Store url */
  storeUrl: string;
  /** Product/Advertiser domain name */
  domain: string;
  /** Notes */
  notes: string;
  /** Product custom url */
  customUrl: string;
  /** Is this product active now (set by automation) */
  active: boolean;
  /** Bid only after all ORD activities is ready */
  bidAfterORD: boolean;
  /** Is the product SKAdNetwork oriented */
  isSKAdNetwork: boolean;
  /** Is Tier 2/3 countries need strict geo checks */
  isStrictGeoCheck: boolean;
  /**
   * Dsp revenue limit for 24 hours
   * @format double
   */
  dailyRevenueLimit: number;
  /**
   * Dsp revenue limit
   * @format double
   */
  totalRevenueLimit: number;
  /**
   * Total Revenue
   * @format double
   */
  totalRevenue: number;
  /** Product archived state */
  archived: boolean;
  /** Is for autopilot */
  isAutopilot: boolean;
  /** Autopilot id list */
  autopilotIDList: number[];
  /** Product Tracking information */
  tracking: ProductTrackingDto;
  /** Is product tracking loaded */
  isTrackingLoaded: boolean;
  /** Product targeting */
  targeting: ProductTargetingDto;
  /** Is product targeting loaded */
  isTargetingLoaded: boolean;
  /** IAB Category List */
  iabCategoryList: string[];
  /** Attribution type */
  attributionTyp: EnumProductInitialAttributionType;
  /** Campaign statistics */
  stats: CampaignStatsDto;
  /**
   * Updated at (necessary for update)
   * @format date-time
   */
  updatedAt: string;
  type: EnumProductType;
  status: EnumProductStatus;
  /** Labeling settings */
  labelSettings?: LabelSettingsDto;
  bindingList: KeyValuePairDto[];
}

export enum EnumBundleType {
  application = 'application', // NO DESCRIPTION
  web = 'web', // NO DESCRIPTION
}
/** Application store bundle information */
export interface BundleDto {
  /**
   * Bundle ID
   * @format int32
   */
  bundleID: number;
  /** Bundle name */
  bundle: string;
  bundleType: EnumBundleType;
  operationSystem: EnumOperationSystem;
  /** Application title */
  title: string;
  /** Image url */
  iconUrl: string;
}

/** Advertiser ID and its access rights for advertiser name */
export interface ReklUserDto {
  /**
   * User ID
   * @format int32
   */
  userID: number;
}

/** Advertiser description */
export interface ReklDto {
  /** List advertisers with their rights */
  userList: ReklUserDto[];
  /** List of products for this advertisers */
  reklBundleIDList: number[];
  /**
   * Advertiser ID
   * @format int32
   */
  reklID: number;
  /**
   * Organization ID
   * @format int32
   */
  organizationID: number;
  /** Advertiser name */
  name: string;
  /** Notes */
  notes: string;
  /** Campaign statistics */
  stats: CampaignStatsDto;
  /**
   * Updated at (necessary for update)
   * @format date-time
   */
  updatedAt: string;
  /** Advertiser custom tag */
  customTagList: KeyValuePairDto[];
}

/** Rekl bundle link */
export interface ReklBundleDto {
  /**
   * Rekl Bundle ID
   * @format int32
   */
  reklBundleID: number;
  /**
   * Rekl ID
   * @format int32
   */
  reklID: number;
  /**
   * Bundle ID
   * @format int32
   */
  bundleID: number;
  /** Appsflyer api token */
  appsflyerApiToken: string;
  /** MParticle api token */
  mParticleApiToken: string;
  /** Notes */
  notes: string;
  /** Allow in lite-ui */
  allowInLiteUI: boolean;
  /** Product ID List */
  productIDList: number[];
}

export interface TrackingDto {
  payoutFee: number; // DSP CPM Fee

  impressionRedirect: TrackerGenerateDtoEntity; // Impression redirect
  clickRedirect: TrackerGenerateDtoEntity; // Click redirect
  impressionS2S: TrackerGenerateDtoEntity; // Impression s2s
  clickS2S: TrackerGenerateDtoEntity; // Click s2s
}

/** CPM settings */
export interface CampaignCPMDto {
  /** CPM settings */
  bid: CampaignBidDto;
  /** BidCPM by Applications & Tags */
  customBidCPMList: CustomBidCPMDto[];
}

export enum EnumPaceKeeperStatus {
  None = 'none',
  Enabled = 'enabled',
  Resetting = 'resetting',
}

export enum EnumColdstartStatus {
  None = 'none',
  Processing = 'processing',
  Complete = 'complete',
  Error = 'error',
}

export interface EnumPaceKeeperStatusOptionValue {
  /** Is value presented */
  presented: boolean;
  value: EnumPaceKeeperStatus;
}

export interface EnumColdstartStatusOptionValue {
  /** Is value presented */
  presented: boolean;
  value: EnumColdstartStatus;
}
export interface OptimizationDto {
  /** Use auto blacklisting */
  useAutoBL: boolean;
  /** Use balancer */
  useBalancer: boolean;
  /**
   * Auto blacklisting CPI payout multiplier
   * @format double
   */
  autoBLCPIMulti: number;
  /**
   * Daily revenue limit
   * @format double
   */
  dailyRevenueLimit: number;
  paceKeeperStatus: EnumPaceKeeperStatusOptionValue;
  coldstartStatus: EnumColdstartStatusOptionValue;
  /** Labeling settings */
  labelSettings?: LabelSettingsDto;
}

/** Information of aggregated by campaign */
export interface CampaignStatsGroupResultDto {
  /** Statistics lists */
  statList: CampaignStatsDto[];
  /** Campaign statistics */
  total: CampaignStatsDto;
  /** Allow White/Black listing for this grouping */
  allowBlackWhite: boolean;
  /** Allow bidCPM change for the set of fields */
  allowBidCPMChange: boolean;
}

/** Exchange description object */
export interface ExchangeDto {
  /** ID */
  exchangeID: string;
  /** Name */
  title: string;
  /** Enabled */
  enabled: boolean;
}

/** Language object */
export interface LanguageDto {
  /** ISO-2 Code */
  languageID: string;
  /** Name */
  title: string;
}

/** Pair key - value */
export interface KeyValuePairDto {
  /** Key */
  key: string;
  /** Value */
  value: string;
}

/** Campaign basic data */
export interface CampaignBasicInfoDto {
  /** Name */
  name: string;
  /** Client title */
  clientTitle: string;
  /** Description */
  description: string;
  /**
   * Campaign creation date
   * @format date-time
   */
  createdAt: string;
  /**
   * Last campaign update date
   * @format date-time
   */
  updatedAt: string;
  /**
   * Campaign creation manager
   * @format int32
   */
  managerID: number;
  /**
   * Available campaign budget
   * @format double
   */
  budget: number;
  /** Markup variable list */
  markupVariableList: KeyValuePairDto[];
  /** Bindengs */
  bindingList: KeyValuePairDto[];
  campaignState: EnumCampaignState;
  status: EnumCampaignStatus;
  /** Is data in campaign valid for ML */
  isValidForML: boolean;
}

/** Information of aggregated by campaign */
export interface CampaignStatsGroupResultDto {
  /** Statistics lists */
  statList: CampaignStatsDto[];
  /** Campaign statistics */
  total: CampaignStatsDto;
  /** Allow White/Black listing for this grouping */
  allowBlackWhite: boolean;
  /** Allow bidCPM change for the set of fields */
  allowBidCPMChange: boolean;
}

/** Model & predictor multiplier adjusted information */
export interface PredictorCampaignMultiplierAdjDto {
  /**
   * ID
   * @format int32
   */
  predictorCampaignMultiplierAdjID: number;
  /**
   * Min probability
   * @format double
   */
  minProbability: number;
  /**
   * Max probability
   * @format double
   */
  maxProbability: number;
  /**
   * Bid price multiplier
   * @format double
   */
  multiplier: number;
}

export enum EnumPredictorMLearnMultiplierType {
  adjusted = 'adjusted',
  creative = 'creative',
  ifa = 'ifa',
  prediction = 'prediction',

}
/** Model & predictor multiplier information */
export interface PredictorMLearnMultiplierDto {
  /**
   * ID
   * @format int32
   */
  predictorMLearnMultiplierID: number;
  /**
   * Min probability
   * @format double
   */
  minProbability: number;
  /**
   * Max probability
   * @format double
   */
  maxProbability: number;
  /**
   * Bid price multiplier
   * @format double
   */
  multiplier: number;

  /** Multiplier type */
  type: EnumPredictorMLearnMultiplierType;
  creativeType: EnumCreativeType | null;
  /** Interstitial */
  interstitial: EnumNullableBoolean;
  /** Ifa */
  ifa: EnumNullableBoolean;

}

export enum EnumPricerType {
  Default = 'default',
  Average = 'average',
}

/** Model information */
export interface CampaignPredictorModelDto {
  /** Is autopilot tempalte */
  isAutopilotTemplate: boolean;
  /** Need to use predictor */
  usePredictor: boolean;
  /** Need to use ext predictor */
  useExtPredictor: boolean;
  /**
   * Model ID
   * @format int32
   */
  predictorModelID: number;
  /**
   * Target price for conversion
   * @format double
   */
  targetPrice: number;
  pricerType: EnumPricerType;
  /** List of multipliers */
  multiplierList: PredictorMLearnMultiplierDto[];
  /** List of adjusted multipliers */
  multiplierAdjList: PredictorCampaignMultiplierAdjDto[];
}

/** Tracking information */
export interface CampaignTrackingDto {
  /**
   * Agency CPM Fee
   * @format double
   */
  payoutFee: number;
  /** Impression redirect */
  impressionRedirect: string;
  /** Click redirect */
  clickRedirect: string;
  /** Impression s2s */
  impressionS2S: string;
  /** Click s2s */
  clickS2S: string;
  /** Impression JavaScript code */
  impressionNativeJS: string;
}

export interface CampaignDto {
  /**
   * CampaignID
   * @format int32
   */
  campaignID: number;
  /** Campaign basic data */
  basicInfo: CampaignBasicInfoDto;
  /**
   * Product information
   * @format int32
   */
  productID: number;
  /** Tracking information */
  tracking: CampaignTrackingDto;
  /**
   * Autopilot ID
   * @format int32
   */
  autopilotID: number | null;
  /** CPM settings */
  campaignCPM: CampaignCPMDto;
  /** Model information */
  predictorModel: CampaignPredictorModelDto;
  /** Auto settings */
  optimization: OptimizationDto;
  /** Targeting list */
  targeting: TargetingDto;
  /** Campaign creative list */
  creativeList: number[] | null;
  /** Campaign statistics */
  stats: CampaignStatsDto;
  /** Information of aggregated by campaign */
  statsGroupResult: CampaignStatsGroupResultDto;
  /** Is allowed for requested user/api to edit */
  isAllowEditing: boolean;
}

/** Custom bidCPM object description */
export interface CustomBidCPMDto {
  /**
   * ID
   * @format int32
   */
  campaignAppBidPayoutID: number;
  /** Application bundle */
  application: string;
  /** Placement ID */
  placementID: string;
  /** CPM settings */
  bid: CampaignBidDto;
}

/** Tracking link dto */
export interface TrackingLinkDto {
  /** Tracker link query params (empty = default) */
  queryParams: KeyValuePairDto[];
  /** Base url (empty = default) */
  baseUrl: string;
  /** Disable this tracking link */
  disabled: boolean;
  /** Tracker link baseUrl params (empty = default) */
  baseUrlParams: KeyValuePairDto[];
  /** Link preview (read-only) */
  preview: string;
}

export interface TrackerGenerateDtoEntity {
  /**
   * Tracker ID
   * @format int32
   */
  trackerID: number;
  linkType: EnumTrackerLinkType;
  operationSystem: EnumOperationSystem;
  callType: EnumTrackerCallType;
  /** Tracker link query params (empty = default) */
  queryParams: KeyValuePairDto[];
  /** Base url params (empty = default) */
  baseUrlParams: KeyValuePairDto[];
  /** Base url (empty = default) */
  baseUrl: string | null;
  /** Link preview (read-only) */
  preview: string | null;
}

export enum EnumOperationSystem {
  Ios = 'ios',
  Android = 'android',
  Windows = 'windows',
  Web = 'web',
}

/** Non entity data container */
export interface RawResponseDto {
  /** Generated tracking links */
  trackerRawResponseList: TrackerGenerateDtoEntity[];
  /** Information of aggregated by campaign */
  groupedStats: CampaignStatsGroupResultDto;
  /** Information of stats by organizations */
  organizationStats: OrganizationStatsResultDto;
  /** Organization statistics details */
  organizationStatsDetails: OrganizationStatsRowItemResultDto[];
  /** Markup rendered from a template */
  markup: string;
  /** Time series statistics */
  timeSeriesStats: CampaignStatsDto[];
  /** Time series statistics */
  timeSeriesByTargetStats: TimeSeriesByTargetStatsDto[];
  /** Time series rejects statistics */
  timeSeriesRejectsStats: RejectStatsDto[];
  /** Organization predictor response */
  /** Bundle container list */
  // bundleList: BundleContainer[];
  // billing: Billing;
  /** Campaign list QPS */
  // campaignQpsList: CampaignQps[];
}

export enum EnumRejectLevel {
  budget = 'budget', // Reason level
  targeting = 'targeting', // Reason level
  creative = 'creative', // Reason level
  product = 'product', // Reason level
  predictor = 'predictor', // Reason level
  global = 'global', // Reason level,
}

/** Reject reason */
export interface RejectDto {
  /** Reason code */
  reason: string;
  /** Reason description */
  description: string;
  level: EnumRejectLevel;
  /**
   * Priority
   * @format int32
   */
  priority: number;
}

/** Campaign statistic data by reject type */
export interface RejectStatsDto {
  /** Reject type */
  reason: string;
  /**
   * Reject amount
   * @format int64
   */
  amount: number;
  /**
   * Creative
   * @format int32
   */
  creativeID: number;
  /**
   * Campaign
   * @format int32
   */
  campaignID: number;
  /**
   * Product
   * @format int32
   */
  productID: number;
  /**
   * Rekl
   * @format int32
   */
  reklID: number;
  /**
   * TimeSeriesAt
   * @format date-time
   */
  timeSeriesAt: string;
}

/** Information of aggregated by campaign */
export interface CampaignStatsGroupResultDto {
  /** Statistics lists */
  statList: CampaignStatsDto[];
  /** Campaign statistics */
  total: CampaignStatsDto;
  /** Allow White/Black listing for this grouping */
  allowBlackWhite: boolean;
  /** Allow bidCPM change for the set of fields */
  allowBidCPMChange: boolean;
}

/** List of filters for statistics */
export interface CampaignStatsFilterDto {
  /** Campaign ID List */
  campaignIDList: number[];
  /** Advertiser ID List */
  reklIDList: number[];
  /** Product list */
  productIDList: number[];
  /** Search string */
  search: string;
  attributionType: EnumAttributionType;
  /** Additional filtering information in disjunctive normal form */
  filterList: FilterElementOr[];
  /** Campaign states */
  campaignStates: EnumCampaignState[];
  /** Owners/managers list */
  managerIDList: number[];
  /** Office list */
  // NOT: frontend does not use it
  // officeList: string[];
  /** Only favorite rekl */
  onlyFavoriteRekls: boolean;
  /** Process archived campaigns too */
  archivedCampaigns: boolean;
  /** Is it necessary to return inactive products */
  includeInactiveProducts: boolean;

  // only client 
  targetingContainerState: EnumTargetingContainerState;
}

/** Grouping data */
export interface CampaignStatsGroupDto {
  /** Grouping keys list */
  groupingKeyList: EnumGroupingKey[]; // Grouping keys list
}

/** Sorting for stats */
export interface CampaignStatsOrderingDto {
  /** Key of sorting */
  orderingList: StatsOrderingEntityDto[]; // Key of sorting
}

/** Filtering element OR */
export interface FilterElementOr {
  /** Filtering value */
  elementAndList: FilterElementAnd[]; // Filtering value
}

/** Sorting entity level */
export interface StatsOrderingEntityDto {
  orderingKey: EnumStatsColumnName;
  orderingDirection: EnumOrderingDirection;
}

/** Filtering element AND */
export interface FilterElementAnd {
  /** Use not? */
  not: boolean;
  name: EnumName;
  operator: EnumFilterEntityType;
  /** Value (JSON stringify) */
  value: string;
}

// segment
export enum EnumFetchType {
  toHostAndDMP = 'toHostAndDMP', // Amount of records
  toDMP = 'toDMP', // Amount of records,
}
export enum EnumSegmentStatus {
  initial = 'initial', // Status
  dataDownload = 'dataDownload', // Status
  dataUpload = 'dataUpload', // Status
  inProcess = 'inProcess', // Status
  success = 'success', // Status
  failure = 'failure', // Status
  appsflyerWaitingFetch = 'appsflyerWaitingFetch', // Status
  refetchDataDownload = 'refetchDataDownload', // Status
  refetchDataUpload = 'refetchDataUpload', // Status
  readyToProcess = 'readyToProcess', // Status,
}
export enum EnumCreationType {
  file = 'file', // Type of creation process
  list = 'list', // Type of creation process
  url = 'url', // Type of creation process,
}

/** Information about devices segment list */
export interface SegmentDto {
  /**
   * Created date
   * @format date-time
   */
  createdAt: string;
  /**
   * Completed date (if success)
   * @format date-time
   */
  completedAt: string;
  /**
   * ID
   * @format int32
   */
  segmentID: number;
  /** Comment */
  comment: string;
  status: EnumSegmentStatus;
  /**
   * Processed items success
   * @format int32
   */
  itemsSuccess: number;
  /**
   * Processed items failed
   * @format int32
   */
  itemsFailed: number;
  /** Url */
  url: string;
  creationType: EnumCreationType;
  /** Is it a segment */
  isSegment: boolean;
  /** Is it a ml set */
  isMLSet: boolean;
  /** Is it a test devices list */
  isTestSet: boolean;
  /** Was suppression list */
  isSuppression: boolean;
  /** Is this segment from appsflyer */
  isAppsflyer: boolean;
  /** Is this segment from mParticle */
  isMParticle: boolean;
  /** Is this segment removed */
  isRemoved: boolean;
  /** List of ifa on creation */
  ifaList: string;
  /** Rekl Bundle Id */
  reklBundleIDList: number[];
}

export enum EnumResourceStatus {
  waiting = 'waiting',
  ready = 'ready',
  failed = 'failed',
}

/** Resource (image, video, json, segment) */
export interface ResourceUploadRequestDto {
  /**
   * If used cropping -- left top x
   * @format int32
   */
  leftTopX: number;
  /**
   * If used cropping -- left top y
   * @format int32
   */
  leftTopY: number;
  /**
   * Width of cropping (skipped if 0)
   * @format int32
   */
  width: number;
  /**
   * Height of cropping (skipped if 0)
   * @format int32
   */
  height: number;
  /**
   * Width of target resize (skipped if 0)
   * @format int32
   */
  targetWidth: number;
  /**
   * Height of target resize (skipped if 0)
   * @format int32
   */
  targetHeight: number;
  /**
   * For video start time for fragment (0 -- skipped)
   * @format double
   */
  videoStartTime: number;
  /**
   * For video end time for fragment (0 -- skipped)
   * @format double
   */
  videoEndTime: number;
  /**
   * Video quality (default is 28) (range is between 0 and 51)
   * @format int32
   */
  videoQuality: number;
  /** Use jpeg image format */
  useJpeg: boolean;
  /**
   * Skip optimization (do not recommend to set this parameter to true). Applicable only for images. On video there
   * would be processing
   */
  skipOptimization: boolean;
  /** Description */
  description: string;
}

export interface ResourceDto {
  /**
   * Resource ID
   * @format int32
   */
  resourceID: number;
  /** File name */
  fileName: string;
  /** File description */
  description: string;
  /** File path */
  filePath: string;
  fileType: EnumResourceType;
  /**
   * Width
   * @format int32
   */
  width: number;
  /**
   * Height
   * @format int32
   */
  height: number;
  /**
   * Duration
   * @format int32
   */
  duration: number;
  /** Mime type */
  mimeType: string;
  /** Poster Url */
  posterUrl: string;
  /**
   * File size
   * @format int32
   */
  fileSize: number;
  /** File size */
  fileHash: string;
  /**
   * Amount of records for segments
   * @format int32
   */
  rows: number;
  status: EnumResourceStatus;
  /**
   * Created at
   * @format date-time
   */
  createdAt: string;
  /**
   * Updated at
   * @format date-time
   */
  updatedAt: string;
}

// markup
export enum EnumMarkupVariableType {
  PositiveNumber = 'positiveNumber',
  Boolean = 'boolean',
  Code = 'code',
  Any = 'any',
  Number = 'number',
  Integer = 'integer',
  Selector = 'selector',
  Duration = 'duration',
}

/** Markup variable information */
export interface MarkupVariable {
  /** Variable name */
  key: string;
  /** Description of variable */
  description: string;
  type: EnumMarkupVariableType;
  /** Meta information for type selector */
  meta: string;
  /**
   * Order in variable list
   * @format int32
   */
  order: number;
  /** Is value required */
  isRequired: boolean;
  /** Default value */
  defaultValue: string;
  /** Test value */
  testValue: string;
}

export enum EnumResourceType {
  Image = 'image',
  Video = 'video',
  Resource = 'resource',
  Segment = 'segment',
  SegmentPart = 'segmentPart',
}

/** Resource information */
export interface MarkupResource {
  /** Resource name */
  name: string;
  /** Description of resource */
  description: string;
  type: EnumResourceType;
  /** Is Resource required */
  isRequired: boolean;
  /**
   * Test resource ID
   * @format int32
   */
  testValue: number;
  /**
   * Resource order
   * @format int32
   */
  order: number;
}

export interface MarkupContent {
  /** List of required resources */
  resourceList: MarkupResource[]; // List of required resources
  /** List of variables */
  variableList: MarkupVariable[]; // List of variables
  /** List of test variables */
  variableTestList: KeyValuePairDto[]; // List of test variables
}

export enum EnumCreativeType {
  banner = 'banner', // NO DESCRIPTION
  video = 'video', // NO DESCRIPTION
  native = 'native', // NO DESCRIPTION
}

export enum EnumOpenRTBVastProtocol {
  none = 'none', // NO DESCRIPTION
  vast1 = 'vast1', // NO DESCRIPTION
  vast2 = 'vast2', // NO DESCRIPTION
  vast3 = 'vast3', // NO DESCRIPTION
  vast1Wrapper = 'vast1Wrapper', // NO DESCRIPTION
  vast2Wrapper = 'vast2Wrapper', // NO DESCRIPTION
  vast3Wrapper = 'vast3Wrapper', // NO DESCRIPTION
}

export enum EnumOpenRTBApiFramework {
  None = 'none',
  Vpaid1 = 'vpaid1',
  Vpaid2 = 'vpaid2',
  Mraid1 = 'mraid1',
  Ormma = 'ormma',
  Mraid2 = 'mraid2',
  Mraid3 = 'mraid3',
  Omid = 'omid',
}

export enum EnumPlatform {
  iOS = 'iOS', // Platform (ios, android)
  android = 'android', // Platform (ios, android),
}
export enum EnumHttpStatusCode {
  continue = 'continue', // NO DESCRIPTION
  switchingProtocols = 'switchingProtocols', // NO DESCRIPTION
  processing = 'processing', // NO DESCRIPTION
  earlyHints = 'earlyHints', // NO DESCRIPTION
  oK = 'oK', // NO DESCRIPTION
  created = 'created', // NO DESCRIPTION
  accepted = 'accepted', // NO DESCRIPTION
  nonAuthoritativeInformation = 'nonAuthoritativeInformation', // NO DESCRIPTION
  noContent = 'noContent', // NO DESCRIPTION
  resetContent = 'resetContent', // NO DESCRIPTION
  partialContent = 'partialContent', // NO DESCRIPTION
  multiStatus = 'multiStatus', // NO DESCRIPTION
  alreadyReported = 'alreadyReported', // NO DESCRIPTION
  iMUsed = 'iMUsed', // NO DESCRIPTION
  multipleChoices = 'multipleChoices', // NO DESCRIPTION
  ambiguous = 'ambiguous', // NO DESCRIPTION
  movedPermanently = 'movedPermanently', // NO DESCRIPTION
  moved = 'moved', // NO DESCRIPTION
  found = 'found', // NO DESCRIPTION
  redirect = 'redirect', // NO DESCRIPTION
  seeOther = 'seeOther', // NO DESCRIPTION
  redirectMethod = 'redirectMethod', // NO DESCRIPTION
  notModified = 'notModified', // NO DESCRIPTION
  useProxy = 'useProxy', // NO DESCRIPTION
  unused = 'unused', // NO DESCRIPTION
  temporaryRedirect = 'temporaryRedirect', // NO DESCRIPTION
  redirectKeepVerb = 'redirectKeepVerb', // NO DESCRIPTION
  permanentRedirect = 'permanentRedirect', // NO DESCRIPTION
  badRequest = 'badRequest', // NO DESCRIPTION
  unauthorized = 'unauthorized', // NO DESCRIPTION
  paymentRequired = 'paymentRequired', // NO DESCRIPTION
  forbidden = 'forbidden', // NO DESCRIPTION
  notFound = 'notFound', // NO DESCRIPTION
  methodNotAllowed = 'methodNotAllowed', // NO DESCRIPTION
  notAcceptable = 'notAcceptable', // NO DESCRIPTION
  proxyAuthenticationRequired = 'proxyAuthenticationRequired', // NO DESCRIPTION
  requestTimeout = 'requestTimeout', // NO DESCRIPTION
  conflict = 'conflict', // NO DESCRIPTION
  gone = 'gone', // NO DESCRIPTION
  lengthRequired = 'lengthRequired', // NO DESCRIPTION
  preconditionFailed = 'preconditionFailed', // NO DESCRIPTION
  requestEntityTooLarge = 'requestEntityTooLarge', // NO DESCRIPTION
  requestUriTooLong = 'requestUriTooLong', // NO DESCRIPTION
  unsupportedMediaType = 'unsupportedMediaType', // NO DESCRIPTION
  requestedRangeNotSatisfiable = 'requestedRangeNotSatisfiable', // NO DESCRIPTION
  expectationFailed = 'expectationFailed', // NO DESCRIPTION
  misdirectedRequest = 'misdirectedRequest', // NO DESCRIPTION
  unprocessableEntity = 'unprocessableEntity', // NO DESCRIPTION
  locked = 'locked', // NO DESCRIPTION
  failedDependency = 'failedDependency', // NO DESCRIPTION
  upgradeRequired = 'upgradeRequired', // NO DESCRIPTION
  preconditionRequired = 'preconditionRequired', // NO DESCRIPTION
  tooManyRequests = 'tooManyRequests', // NO DESCRIPTION
  requestHeaderFieldsTooLarge = 'requestHeaderFieldsTooLarge', // NO DESCRIPTION
  unavailableForLegalReasons = 'unavailableForLegalReasons', // NO DESCRIPTION
  internalServerError = 'internalServerError', // NO DESCRIPTION
  notImplemented = 'notImplemented', // NO DESCRIPTION
  badGateway = 'badGateway', // NO DESCRIPTION
  serviceUnavailable = 'serviceUnavailable', // NO DESCRIPTION
  gatewayTimeout = 'gatewayTimeout', // NO DESCRIPTION
  httpVersionNotSupported = 'httpVersionNotSupported', // NO DESCRIPTION
  variantAlsoNegotiates = 'variantAlsoNegotiates', // NO DESCRIPTION
  insufficientStorage = 'insufficientStorage', // NO DESCRIPTION
  loopDetected = 'loopDetected', // NO DESCRIPTION
  notExtended = 'notExtended', // NO DESCRIPTION
  networkAuthenticationRequired = 'networkAuthenticationRequired', // NO DESCRIPTION,
}

/** Creative markup information */
export interface MarkupDto {
  /* @OnlyForFrontend */
  __checkVariables?: boolean // NOTE: only client side 

  /**
   * Markup ID
   * @format int32
   */
  markupID: number;
  /** Name of the markup template */
  name: string;
  /** Description of the markup template */
  description: string;
  /** Code of the template */
  templateOriginal: string;
  /** Minified template */
  templateMinified: string;
  /**
   * New template published at
   * @format date-time
   */
  publishedAt: string;
  /** Markup content container */
  markupContent: MarkupContent;
  /**
   * Created at
   * @format date-time
   */
  createdAt: string;
  /**
   * Updated at
   * @format date-time
   */
  updatedAt: string;
  state: EnumMarkupState;
  type: EnumCreativeType;
  /** Vast version for video */
  vastVersionList: EnumOpenRTBVastProtocol[];
  /** API for MRAID & etc. */
  apiList: EnumOpenRTBApiFramework[];
  /** Is it playable */
  isPlayable: boolean;
  /**
   * Width
   * @format int32
   */
  width: number;
  /**
   * Height
   * @format int32
   */
  height: number;
  /**
   * Duration
   * @format int32
   */
  duration: number;
  /** Is it in banner video */
  isInBannerVideo: boolean;
  interstitial: EnumNullableBoolean;
  /** ContextDto (don't stored) */
  renderContext: string;
  /** Exchanges on which markup is tested list */
  exchangeIDList: string[];
  /** Organization list for this markup */
  organizationIDList: number[];
}

export enum EnumHistoryLogEntityLevel {
  notSet = 'notSet', // NO DESCRIPTION
  advertiser = 'advertiser', // NO DESCRIPTION
  product = 'product', // NO DESCRIPTION
  campaign = 'campaign', // NO DESCRIPTION
  creative = 'creative', // NO DESCRIPTION
}

/** History log event information */
export interface HistoryLogDto {
  /**
   * UTC time of event occurrence
   * @format date-time
   */
  createdAt: string;
  /**
   * Campaign ID
   * @format int32
   */
  campaignID: number;
  /**
   * Creative ID
   * @format int32
   */
  creativeID: number;
  /**
   * User ID
   * @format int32
   */
  userID: number;
  /** User email */
  userEmail: string;
  /**
   * Rekl ID
   * @format int32
   */
  reklID: number;
  /** What was changed */
  message: string;
  /**
   * Product ID
   * @format int32
   */
  productID: number;
  changedEntityLevel: EnumHistoryLogEntityLevel;
  /**
   * Api ID
   * @format int32
   */
  apiID: number;
  /** Api title */
  apiTitle: string;
  /**
   * OrganizationID
   * @format int32
   */
  organizationID: number;
}

/** History log list request */
export interface HistoryLogListRequestDto {
  /** Client request ID */
  requestID: string;
  /** Client version */
  clientVersion: string;
  /** Custom result list */
  responseResultList: EnumResponseListType[];
  /** Token */
  sessionToken: string;
  /** Admin session token */
  adminSessionToken: string;
  /** Use rekl figures layer */
  useReklFigures: boolean;
  /** Are request dates in UTC already */
  requestTzUtc: boolean;
  /** Product ID */
  productIDList: number[];
  /** Campaign ID */
  campaignIDList: number[];
  /** Creative ID */
  creativeIDList: number[];
  /** User ID */
  userIDList: number[];
  /**
   * Max known history log timestamp (will be returned lower than it)
   * @format date-time
   */
  maxHistoryLogDateTime: string;
  /**
   * Amount of records to return
   * @format int32
   */
  count: number;
  /** Search string */
  search: string;
  onlyManual: boolean;
}

export enum EnumChangeSetAction {
  save = 'save',
  remove = 'remove',
  add = 'add',
}

export enum EnumReklUserFavoriteActionType {
  add = 'add',
  remove = 'remove',
}

export enum EnumBidCPMType {
  bidCpm = 'bidCpm',
  bidFloorCap = 'bidFloorCap',
}

/** CPM settings */
export interface CampaignBidDto {
  type: EnumBidCPMType;
  /**
   * Static Bid CPM
   * @format double
   */
  bidCpm: number;
  /**
   * Maximum Bid CPM for bid floor + cap
   * @format double
   */
  maxBidCpm: number;
  /**
   * Additional amount of money added to bid floor
   * @format double
   */
  bidFloorCap: number;
}

export enum EnumProperty {
  campaignState = 'campaignState', // Refreshing property
  applicationList = 'applicationList', // Refreshing property
  hour = 'hour', // Refreshing property
  operationSystemVersionList = 'operationSystemVersionList', // Refreshing property
  makeList = 'makeList', // Refreshing property
  modelList = 'modelList', // Refreshing property
  cityList = 'cityList', // Refreshing property
  countryList = 'countryList', // Refreshing property
  regionList = 'regionList', // Refreshing property
  exchangeList = 'exchangeList', // Refreshing property
  predictorMultiplier = 'predictorMultiplier', // NO DESCRIPTION
  isFavorite = 'isFavorite', // Refreshing property
  bidCpm = 'bidCpm', // Refreshing property
  bidCpmAppTag = 'bidCpmAppTag', // Refreshing property
  budget = 'budget', // Refreshing property
}

/** Change set DTO */
export interface CampaignChangeSetEntityDto {
  /**
   * Campaign ID
   * @format int32
   */
  campaignID: number;
  property: EnumProperty;
  /** Value */
  value: string;
  action: EnumChangeSetAction;
}

export enum EnumGender {
  f = 'f', // Gender
  m = 'm', // Gender,
}

/** Exchange loss reason */
export interface ExchangeLossReasonDto {
  /**
   * Exchange Loss ID
   * @format int32
   */
  exchangeLossReasonID: number;
  /** Name */
  title: string;
}

// Report Export
export enum EnumReportTaskLevel {
  product = 'product', // NO DESCRIPTION+}
  campaign = 'campaign', // NO DESCRIPTION+}
  aggregation = 'aggregation', // NO DESCRIPTION+}
}

export enum EnumEventType {
  bid = 'bid',
  win = 'win',
  loss = 'loss',
  conv = 'conv',
  adm = 'adm',
  imp = 'imp',
  click = 'click',
  error = 'error',
  event = 'event',
  impCr = 'impCr',
  reject = 'reject',
}

/** Report task prototype */
export interface ReportTaskPrototypeDto {
  /**
   * ID
   * @format int32
   */
  reportTaskPrototypeID: number;
  /** Description */
  description: string;
  /** Is this task active */
  isActive: boolean;
  /** Extra email list */
  extraEmailList: string[];
  /**
   * Created date
   * @format date-time
   */
  createdAt: string;
  /**
   * Stopped date
   * @format date-time
   */
  stoppedAt: string;
  /**
   * When this task was activated
   * @format date-time
   */
  activatedAt: string;
  level: EnumReportTaskLevel;
  /** Is it one time export */
  isOneTimeExport: boolean;
  /**
   * Count of left report runs
   * @format int32
   */
  leftRunCount: number;
  /**
   * How often this report should run (1 time in X days)
   * @format int32
   */
  frequencyDays: number;
  /**
   * what is the days difference between date from and date to
   * @format int32
   */
  daysDepth: number;
  /**
   * Date of next run
   * @format date-time
   */
  nextRun: string;
  /** Statistics by campaigns */
  settings: CampaignStatsRequestDto;
}

export enum EnumReportStatus {
  inProgress = 'inProgress', // Status
  success = 'success', // Status
  failure = 'failure', // Status,
}
export interface ReportTaskDto {
  reportTaskID: number; // ID
  reportTaskPrototypeID: number; // Prototype
  startedAt: string; // Started at
  finishedAt: string; // Finished at
  status: EnumReportStatus;
  downloadUrl: string; // Url to file download
  size: number; // File size
}
// [[##END]] Report Export

// ModelLearning
/** Predictor model meta container */
export interface PredictorModelMeta {
  /**
   * AUC
   * @format double
   */
  auc: number;
  /**
   * Best Iteration
   * @format int32
   */
  bestIteration: number;
  /**
   * Sample size
   * @format int32
   */
  examples: number;
}

/** Model data */
export interface PredictorModelDto {
  /**
   * ID
   * @format int32
   */
  predictorModelID: number;
  /**
   * MLearn Task ID
   * @format int32
   */
  mLearnTaskID: number;
  /** Path */
  path: string;
  /** Predictor model meta container */
  meta: PredictorModelMeta;
  /**
   * Created At
   * @format date-time
   */
  createdAt: string;
}

export enum EnumMLearnTaskDtoStatus {
  New = 'new',
  InProgress = 'inProgress',
  Ok = 'ok',
  Error = 'error',
}

/** Model data */
export interface MLearnTaskDto {
  /**
   * ID
   * @format int32
   */
  mLearnTaskID: number;
  /** Task -> Model name */
  name: string;
  status: EnumMLearnTaskDtoStatus;
  /** Worker */
  worker: string;
  /** Message (if error) */
  message: string;
  /**
   * Created date
   * @format date-time
   */
  createdAt: string;
  /**
   * Started date
   * @format date-time
   */
  startedAt: string;
  /**
   * Completed date
   * @format date-time
   */
  stoppedAt: string;
  /**
   * Start date for examples
   * @format date-time
   */
  startDate: string;
  /**
   * End date for examples
   * @format date-time
   */
  endDate: string;
  hasIfa: EnumNullableBoolean;
  /** Special filter condition on goals */
  conditionGoal: string;
  /** Special filter condition on impressions */
  conditionImpression: string;
  retargeting: EnumNullableBoolean;
  /** Goal ID list (1+ goals) */
  goalIDList: string[];
  /** List of campaigns on which education was done */
  campaignIDList: number[];
  /** List of bundles on which education was done */
  bundleTextList: string[];
  /** List of predictor models which were created on this task */
  predictorModelIDList: number[];
}

export enum EnumModelLearningHistoryStatus {
  new = 'new',
  inProgress = 'inProgress',
  success = 'success',
  fail = 'fail',
  cancelled = 'cancelled',
}

// [[####END]] ModelLearning

// SERVER REQUEST DTO
/** Campaign create */
export interface CampaignCreateRequestDto {
  /** Client request ID */
  requestID: string;
  /** Client version */
  clientVersion: string;
  /** Custom result list */
  responseResultList: EnumResponseListType[];
  /** Token */
  sessionToken: string;
  /** Admin session token */
  adminSessionToken: string;
  /** Use rekl figures layer */
  useReklFigures: boolean;
  /** Are request dates in UTC already */
  requestTzUtc: boolean;
  /** Campaign data */
  campaign: CampaignDto;
}

export interface CampaignUpdateRequestDto {
  campaign: CampaignDto;
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
}

export interface CampaignCopyApiRequestDto {
  campaignID: number; // Campaign ID
  token: string; // Token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
  responseResultList: EnumResponseListType[]; // Custom result list
}

export interface ConversionSettingsDto {
  retentionDays: number | null; // Retention days
  roasDays: number | null; // ROAS days
  // Initial attribution type (count only installs or install+retargetings, or reengagements)
  initialAttributionTypeList: EnumInitialAttributionTypeList[];
}

export interface CampaignStatsRequestDto {
  fromDate: string; // Date start
  toDate: string; // Date end
  statsFilter: CampaignStatsFilterDto; // Campaign filters
  statsGroup: CampaignStatsGroupDto; // Group
  statsOrdering: CampaignStatsOrderingDto; // Sorting
  count: number; // Maximum amount of rows to return
  includeNonPayableEvents: boolean; // Need to skip payability feature
  onlyUniqEvents: boolean;
  // (include all events (not only payable) but non-payable too)
  conversionSettings: ConversionSettingsDto; // NO DESCRIPTION
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
}

export interface CampaignDetailsListRequestDto {
  statsFilter: CampaignListStatsFilterDto; // Campaign filters
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
}
export interface CampaignListStatsFilterDto {
  campaignIDList: number[]; // Campaign ID List
  reklIDList: number[]; // Advertiser ID List
  productIDList: number[]; // Product list
}

export enum EnumChangeSetReturnSet {
  nothing = 'nothing', // Return change set type
  changed = 'changed', // Return change set type
  complete = 'complete', // Return change set type,
}
export interface CampaignChangeSetRequestDto {
  changeSetList: CampaignChangeSetEntityDto[]; // Change set list
  changeSetReturnSet: EnumChangeSetReturnSet;
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
}

export interface CampaignTitlesListRequestDto {
  listFilter: CampaignStatsFilterDto; // Campaign filters
  count: number; // Maximum amount of rows to return
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
}

export interface CreativeListFilterDto {
  campaignIDList: number[]; // Campaign ID List
  creativeIDList: number[]; // Creative ID List
  hashList: string[]; // Hash list
  creativeTypeList: string[]; // Creative type list
}
export interface CreativeListRequestDto {
  filter: CreativeListFilterDto; // Filters
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
}
export interface CreativeCreateRequestDto {
  creative: CreativeDto; // Creative
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
}
export interface CreativeUpdateRequestDto {
  creative: CreativeDto; // Creative List
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
}
export interface CreativeCopyRequestDto {
  campaignID: number; // Target Campaign ID
  creativeID: number; // List of creatives to copy
  copyResources: boolean; // Need to copy resources
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
}

export interface MarkupListRequestDto {
  markupIDList: number[]; // Mark up list ID filter
  typeList: string[]; // Type
  inBannerVideo: boolean; // In banner video
  isPlayable: boolean; // Is Playable
  archived: boolean; // archived
  search: string; // Search string
  count: number; // Amount of records to return
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
}
export interface MarkupCopyRequestDto {
  markupIDList: number[]; // Markup ID List to copy
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
}
export interface MarkupUpdateRequestDto {
  checkVariables: boolean; // Should the variables be checked
  updateHash: boolean; // Is it necessary to update hash
  publish: boolean; // Should be markup published
  markup: MarkupDto; // Markup
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
}
export interface MarkupCreateRequestDto {
  markup: MarkupDto; // Markup
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
}

export interface MarkupRenderRequestDto {
  creative: CreativeDto; // Creative
  resourceList: ResourceDto[];
  markup: MarkupDto & { renderContext: string }; // Markup
}

export interface ProductCreateRequestDto {
  product: ProductDto; // Product information
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
}
export interface ProductListRequestDto {
  productIDList: number[]; // ProductID list
  reklBundleIDList: number[]; // ReklBundleID list
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
}
export interface ProductUpdateRequestDto {
  product: ProductDto; // Product
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
}
export interface ProductCopyRequestDto {
  productID: number; // Product ID to copy
  reklBundleID: number; // Target ReklBundle ID
  useReklFigures: boolean; // Use rekl figures layer
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
  responseResultList: EnumResponseListType[]; // Custom result list
}
export interface UserUpdateSubUserRequestDto {
  user: UserDto; // User
  password: string; // Password
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
}

export interface UserReklStateSetRequestDto {
  advertiserEmail: string; // Rekl mail
  isCampaignSettingsView: boolean; // Allow view campaign & product settings
  userID: number; // Rekl ID of rekl
  reklID: number; // Rekl ID
  state: EnumState; // NO DESCRIPTION
  useReklFigures: boolean; // Use rekl figures layer
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
  responseResultList: EnumResponseListType[]; // Custom result list
}
export interface UserAddSubUserRequestDto {
  password: string; // Password
  user: UserDto; // User information
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
}

export interface SegmentCreateRequestDto {
  segment: SegmentDto; // Segment data to be created
  reklID: number; // Rekl Id
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
}
export interface MLearnTaskListRequestDto {
  bundleID: number | undefined; // Bundle list to filter
  name: string; // Name to filter by
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
}
export interface MLearnTaskUpdateRequestDto {
  mLearnTask: MLearnTaskDto; // NO DESCRIPTION
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
}
export interface MLearnTaskCreateRequestDto {
  mLearnTask: MLearnTaskDto; // NO DESCRIPTION
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
}
export interface PredictorModelListRequestDto {
  bundleID: number; // Bundle list to filter
  name: string; // Filter model name by
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
}
export interface ReklUserFavoriteRequestDto {
  reklID: number; // Favorie rekl id list
  action: EnumReklUserFavoriteActionType; // NO DESCRIPTION
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
}
export interface ReklBundleListRequestDto {
  reklBundleIDList: number[]; // Rekl bundle id list
  sessionToken: string; // Token
  onlyFavoriteRekls: boolean;
  adminSessionToken: string; // Admin session token
  useReklFigures: boolean; // Use rekl figures layer
  requestID: string; // Client request ID
  clientVersion: string; // Client version
}
export interface OrganizationStatsRowItemResultDto {
  key: string; // Key
  keyID: number;
  spend: number; // Spend
  grossRevenue: number; // Gross Revenue
  revenue: number; // Revenue
}
export interface OrganizationStatsResultDto {
  revenueByPeriodList: OrganizationStatsRowItemResultDto[]; // Revenue by date/time period
  advertiserList: OrganizationStatsRowItemResultDto[]; // By advertiser
  countryList: OrganizationStatsRowItemResultDto[]; // By country
  genreList: OrganizationStatsRowItemResultDto[]; // By genre
  officeList: OrganizationStatsRowItemResultDto[]; // By office
  organizationList: OrganizationStatsRowItemResultDto[]; // By organization
  exchangeList: OrganizationStatsRowItemResultDto[]; // By exchange
  divisionList: OrganizationStatsRowItemResultDto[]; // By advertiser
  spend: number; // Spend
  grossRevenue: number; // Gross Revenue
  revenue: number; // Revenue
}

export interface VerifySessionRequestDto {
  code: string; // Code
  sessionToken: string; // Token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
  responseResultList: EnumResponseListType[]; // Custom result list
}
export interface BundleListRequestDto {
  search: string; // Search string
  inOrganizationOnly: boolean; // Search bundles used only in organization
  useReklFigures: boolean; // Use rekl figures layer
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
  responseResultList: EnumResponseListType[]; // Custom result list
}
export interface BundleSaveRequestDto {
  bundle: BundleDto; // NO DESCRIPTION
  useReklFigures: boolean; // Use rekl figures layer
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
  responseResultList: EnumResponseListType[]; // Custom result list
}
export interface AdminGlobalSearchRequestDto {
  search: string; // Search string
  organizationIDList: number[]; // Organization id list
  reklIDList: number[]; // Rekl id list
  userIDList: number[]; // User id list
  limit: number; // Lists size limit
  useReklFigures: boolean; // Use rekl figures layer
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
  responseResultList: EnumResponseListType[]; // Custom result list
}
export interface UserRemindPasswordRequestDto {
  email: string; // Email
  requestID: string; // Client request ID
  clientVersion: string; // Client version
  responseResultList: EnumResponseListType[]; // Custom result list
}
export interface BillingStripeCreateRequestDto {
  amount: number; // Amount
  useReklFigures: boolean; // Use rekl figures layer
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
  responseResultList: EnumResponseListType[]; // Custom result list
}
export interface BillingStripePaymentListRequestDto {
  onlyMine: boolean; // NO DESCRIPTION
  useReklFigures: boolean; // Use rekl figures layer
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
  responseResultList: EnumResponseListType[]; // Custom result list
}
export interface BillingStripePaymentRefreshStatusRequestDto {
  organizationPaymentID: number; // Organization Payment ID
  useReklFigures: boolean; // Use rekl figures layer
  sessionToken: string; // Token
  adminSessionToken: string; // Admin session token
  requestID: string; // Client request ID
  clientVersion: string; // Client version
  responseResultList: EnumResponseListType[]; // Custom result list
}
// SERVER REQUEST DTO
// SERVER RESPONSE DTO

export type ICleanServerResponse<T> = { data: T };
export interface CleanServerResponse {
  apiVersion: string;
  expectedUiVersion: string;
  responseStatus: EnumResponseStatus;
  rawResponse: {
    organizationStats: OrganizationStatsResultDto;
    billing: { stripe: { url: string } };
  };
  [EnumListName.timeZoneList]: ICleanServerResponse<TimeZoneDto[]>;
  [EnumListName.sessionList]: ICleanServerResponse<SessionDto[]>;
  [EnumListName.rejectList]: ICleanServerResponse<RejectDto[]>;
  [EnumListName.reklList]: ICleanServerResponse<ReklDto[]>;
  [EnumListName.applicationList]: ICleanServerResponse<TargetingApplicationDto[]>;
  [EnumListName.mLearnTaskList]: ICleanServerResponse<MLearnTaskDto[]>;
  [EnumListName.predictorModelList]: ICleanServerResponse<PredictorModelDto[]>;
  [EnumListName.segmentList]: ICleanServerResponse<SegmentDto[]>;
  [EnumListName.organizationList]: ICleanServerResponse<OrganizationDto[]>;
  [EnumListName.campaignList]: ICleanServerResponse<CampaignDto[]>;
  [EnumListName.productList]: ICleanServerResponse<ProductDto[]>;
  [EnumListName.userList]: ICleanServerResponse<UserDto[]>;
  [EnumListName.countryList]: ICleanServerResponse<CountryDto[]>;
  [EnumListName.exchangeList]: ICleanServerResponse<ExchangeDto[]>;
  [EnumListName.connectionTypeList]: ICleanServerResponse<ConnectionTypeDto[]>;
  [EnumListName.languageList]: ICleanServerResponse<LanguageDto[]>;
  [EnumListName.operationSystemVersionList]: ICleanServerResponse<TargetingOperationSystemVersionDto[]>;
  [EnumListName.sspList]: ICleanServerResponse<SspDto[]>;
  [EnumListName.iabCategoryList]: ICleanServerResponse<IabCategoryDto[]>;
  [EnumListName.creativeList]: ICleanServerResponse<CreativeDto[]>;
  [EnumListName.userAgentList]: ICleanServerResponse<TargetingUserAgentDto[]>;
  [EnumListName.markupList]: ICleanServerResponse<MarkupDto[]>;
  [EnumListName.reportTaskPrototypeList]: ICleanServerResponse<ReportTaskPrototypeDto[]>;
  [EnumListName.cityList]: ICleanServerResponse<TargetingCityDto[]>;
  [EnumListName.zipList]: ICleanServerResponse<TargetingZipDto[]>;
  [EnumListName.reportTaskList]: ICleanServerResponse<ReportTaskDto[]>;
  [EnumListName.historyLogList]: ICleanServerResponse<HistoryLogDto[]>;
  [EnumListName.resourceList]: ICleanServerResponse<ResourceDto[]>;
  [EnumListName.exchangeLossReasonList]: ICleanServerResponse<ExchangeLossReasonDto[]>;
  [EnumListName.trackerList]: ICleanServerResponse<TrackerDto[]>;
  [EnumListName.reklBundleList]: ICleanServerResponse<ReklBundleDto[]>;
  [EnumListName.bundleList]: ICleanServerResponse<BundleDto[]>;
  [EnumListName.organizationPaymentList]: ICleanServerResponse<OrganizationPaymentDto[]>;
  [EnumListName.genreList]: ICleanServerResponse<GenreDto[]>;
}
