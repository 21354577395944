import { Navigate } from 'react-router-dom';
import  get from 'lodash-es/get';
import { useCallback, useEffect, FC, Suspense, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router';

import * as authActions from 'actions/auth';

import AsyncLoadedComponent from 'components/AsyncLoadedComponent';

import { getSessionToken } from 'helpers/utils';
import { type NestedKeyOf } from 'helpers/types';

import API from 'constants/api';
import routes from 'constants/routes';

import useLoading from 'hooks/isLoading';
import { useProfile } from 'hooks/useCdeebee';
import useTypedDispatch from 'hooks/useTypedDispatch';

import { setRedirectValue } from 'helpers/dom';
import { IReturnPermission, permissionObjectChecker } from 'helpers/permission';

import { EnumUserRole } from 'models/objects';

interface IProps {
  children: React.ReactNode;
  permission?: NestedKeyOf<IReturnPermission>;
}

const PrivateRouter: FC<IProps> = ({ children, permission }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useTypedDispatch();

  const profile = useProfile();
  const isLoading = useLoading([API.userProfileRequestDto, API.userTokenDisposeRequestDto]);

  useEffect(() => {
    if (!getSessionToken()) {
      navigate(`${routes.login}${setRedirectValue(location)}`);
    }
  });

  const allowed = useMemo(() => {
    const p = permissionObjectChecker(profile);
    return (p && permission) ? get(p, permission) : true;
  }, [profile, permission]);

  const handleUser = useCallback(() => dispatch(authActions.userProfileRequest()), [dispatch]);

  useEffect(() => {
    if (getSessionToken()) {
      handleUser();
    }
  }, [handleUser]);

  if (isLoading || (!isLoading && !profile)) {
    return <AsyncLoadedComponent />;
  }

  if (profile && !allowed) {
    return <Navigate to={profile.userRole === EnumUserRole.administrator ? routes.summary : routes.index} replace={true} />;
  }

  return <Suspense fallback={<AsyncLoadedComponent />}>{children}</Suspense>;
};

export default PrivateRouter;
