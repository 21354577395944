import isEqual from 'lodash-es/isEqual';

import routes from 'constants/routes';

import types from 'actions/types';
import { campaignListStatsRequest, campaignStatsGroupingRequest } from 'actions/campaigns';

import { IAction } from './types';
import { productStatsListRequest } from 'actions/product';

const useReklFiguresMiddleware = ({ dispatch, getState }: any) => (next: any) => (action: IAction<any>) => {
  const result = next(action);
  if (result.type === types.UI_CHANGE_VALUE) {
    const val = result.payload.valueList?.[0];
    if (val && isEqual(val.key, ['globalStatsFilter', 'useReklFigures'])) {
      const router = getState().router;
      // campaign list
      if (router.location.pathname === routes.campaigns) {
        dispatch(campaignListStatsRequest());
      }

      // campaign detail
      if (router.location.pathname.startsWith(routes.campaignsDetail)) {
        const cmapaignIDList = JSON.parse(decodeURIComponent(router.location.pathname.split(routes.campaignsDetail + '/')[1]));
        dispatch(campaignStatsGroupingRequest(cmapaignIDList));
      }

      // advertiser list
      if (router.location.pathname === routes.advertisers) {
        dispatch(productStatsListRequest());
      }
    }
  }
  return result;
};

export default useReklFiguresMiddleware;
